.checkbox-input-container {
    width: 100%;
    min-height: 50px;
    padding: 0 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;

    .checkbox-label {
        font-size: 16px;
        color: var(--neutral-medium);
    }

    .checkbox-input-container input:checked~.checkmark {
        background-color: var(--primary-color-medium) !important;
        border: 1px solid var(--primary-color-medium) !important;
    }

    .custom-checkbox {
        width: 20px;
        height: 20px;
        appearance: auto;
        cursor: pointer;
    }

    .required-element {
        margin: 12px 0px;
        color: gray;
        font-size: 14px;
    }
}