.sign-in-content {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .sign-in-sub-content {
        flex: 1;
        height: 100vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        position: relative;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .logo-back {
            width: 250px;
            height: 82px;
            background-image: url('../../assets/contractzlab_logo.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
        }

        .form-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .form-section {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                position: relative;
                padding: 0 150px;
                gap: 50px;

                .greeting {
                    font-weight: 550;
                    font-size: 2.6rem;
                    width: 100%;
                    text-align: center;
                }

                .divider-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;

                    .divider {
                        flex: 1;
                        height: 2px;
                        background-color: #E0E0E1;
                        margin-bottom: 15px;
                    }

                    .content {
                        width: 200px;
                        color: #484A4D;
                        text-align: center;
                        font-weight: 400;
                        font-size: 18px;
                    }
                }

                .signin-form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 40px;

                    .side-by-side {
                        display: flex;
                        gap: 20px;
                        width: 100%;

                        div {
                            flex: 1;
                        }
                    }
                }

                .create-account {
                    height: 56px;
                    background-color: #2F14E5;
                    color: white;
                    border-radius: 8px;
                    font-weight: 600;
                    font-size: 1.2rem;
                    box-shadow: none;
                }

                .disabled {
                    background-color: #F5F6F6;
                    color: #B0B0B0;
                    cursor: not-allowed;
                }

                .arabic-suggestion {
                    justify-content: right;
                    align-items: right;
                }

                .signin-suggestion {
                    width: 100%;
                    display: flex;
                    justify-content: left;
                    align-items: left;
                    flex-direction: column;
                    color: #484A4D;

                    .link {
                        color: #2F14E5;
                        font-style: none;
                        text-decoration: none !important;
                        font-weight: 600;
                    }
                }
            }
        }

    }
}