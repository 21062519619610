.comingSoon-container{
    background-color: white;
    border-radius: 34px;
    width: 50%;
    margin: auto;
    margin-top: 3%;
    height:60%;
    position: relative;
}
.comingSoon-container >img{
    position: absolute;
    right: 5vh;
    margin-top: 2%;
}
.comingSoon-sub-content{
    width: fit-content;
    margin: auto;
    padding-top: 10vh;
}
.comingSoon-sub-content >h1{
    color: #13075B;
    font-size: 70px;
    font-weight: bold;
    line-height: 120px;
    text-align: center;
}
.comingSoon-time{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
}
.comingSoon-time >p{
    margin-left: 30px;
    font-size:22px ;
    font-weight: bolder;
    color: black;
}
.comingSoon-time >p>span{
    font-size: 14px;
}
.comingSoon-send-email{
    display: flex;
    justify-content: center;
    margin-top: 5vh;
}
.comingSoon-send-email>input{
    background-color:#EFF0F6 ;
    width: 333px;
    height: 48px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-right: 20px;
    padding-left: 10px;
}.comingSoon-send-email>input::placeholder{
    color: #505050;
}
.comingSoon-send-email>button{
    background-color: #13075B;
    color: white;
    text-align: center;
    width: 114px;
    height: 48px;
    box-shadow: none !important;
}
