.pending-ai-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  .slideshow-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 6px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.background-video{
  width: 700px;
  border-radius: 12px;
  transform: rotate(180deg);
}

.bar-item {
    width: 27.97px;
    height: 5.99px;
    background: rgba(47, 20, 229, 0.25);
    transition: background 0.5s ease-in-out;
    border-radius: 3px;
}

.bar-active {
    width: 47.94px;
    background: #2f14e5;
}

.fading-enter {
    opacity: 0;
    transform: scale(0.9);
}

.fading-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.fading-exit {
    opacity: 1;
}

.fading-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.showcase img {
    height: 260px;
    margin-left: -50px;
}
.title{
  font-weight: 600;
  margin-top: 20px;
  
}
.fading-enter {
    opacity: 0;
    transform: scale(0.9);
}

.fading-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.fading-exit {
    opacity: 0;
    display: none !important;
}

.fading-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
    display: none !important;
}
.slideshow-text {
    font-family: "Hanken Grotesk";
    font-style: normal;
    font-weight: 400;
    color: #8D8E90;
    line-height: 22px;
    /* or 150% */

    text-align: center;
}
}