.wrapper-generic{
  width:100% !important;
    display: flex;
    width: auto !important;
    text-align: center;
    align-items: center;
    margin-top: -2% !important;
    align-self: center;
    margin: auto;
    border-radius: 10px !important;
    width: 100% !important;
    height: 100% !important
}
.slider-option-generic{
  height: 100%!important;
  min-height: 100% !important;
  flex: auto !important;
  background-color: #2f14e5 !important;
  color: #ffffff;
  margin: 2%;
  border-radius: 5px;
}
.selected-type-beneficial{
  border: 3px solid black;
  font-weight: bold;
}