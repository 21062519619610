.custom-combobox-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 390px;

  .custom-combobox-container {
    width: 100%;
    margin: 20px 20px 0px 0px;
    border: 0.1px solid #8aa4d191 !important;
    border-radius: 4px;
  }
  .invalid{
    border: 1px solid red !important;
  }

  .custom-combobox-label {
    display: block;
    color: #333 !important;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 15px;
  }

  .custom-combobox {
    position: relative;
  }

  /* update class */
  .custom-combobox-selected {
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2px;
    font-weight: 400;
    font-size: 14px;
    color: #526484 !important;
    height: 40px;
    border-radius: 2px;
  }

  .custom-combobox-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 3;
    border: 1px solid #ccc;
  }

  .custom-combobox-option {
    padding: 8px;
    cursor: pointer;
  }

  .custom-combobox-option:hover {
    background-color: var(--primary-color-medium);
    color: white;
  }

  .custom-combobox-option-disabled {
    background-color: var(--neutral-light);
    padding: 8px;
    cursor: pointer;
  }
}

.param-type-container {
  width: 100%;
  border: 0.1px solid #8aa4d191 !important;
  border-radius: 4px;

  .param-type-selected {
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2px;
    font-weight: 400;
    font-size: 14px;
    color: #526484 !important;
    height: 30px;
    border-radius: 2px;
    .param-type-actions{
      display: flex;
      gap: 10px;
    }
  }

  .custom-combobox {
    position: relative;
  }

  .param-type-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .param-type-option {
    padding: 4px;
    cursor: pointer;
  }

  .param-type-option:hover {
    background-color: var(--primary-color-medium);
    color: white;
  }
}

/* add new class */
.validate {
  display: flex;
  flex-direction: row-reverse !important;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 40px;
  margin-left: 0;
  margin-bottom: 50px;
}

/* add new class */
.submit-validate-contract-btn {
  width: 200px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 800px !important;
  border: 1px solid #7014E5 !important;
  background: #7014E5 !important;
  color: #FFF;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  margin-left: 55%;
  box-shadow: none !important;
  margin-top: 2%;
  margin-bottom: 2%;
}



.flag-combobox-container {
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  margin-left: 50px;
  margin-right: 50px;
  cursor: pointer;

  .flag-combobox-options {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 30px;
    padding-top: 3px;
  }

  .flag-combobox-option {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #E5E9F2;
  }

  .flag-combobox-selected {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
    border: 1px solid #E5E9F2;
  }

  .custom-combobox {
    position: relative;
  }
}
