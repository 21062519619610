.advanced-table{
  min-height: 400px;
  .resource-table {
    .contract-card-actions-modal {
      border: 1px solid#1c1d2212;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 8px;
      background-color: white;
      width: fit-content;
    }
  
    .contract-card-actions-modal ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  
    .contract-card-actions-modal li {
      cursor: pointer;
      padding: 5px;
      width: 100%;
      transition: background-color 0.3s ease;
    }
  
    .contract-card-actions-modal li:hover {
      background-color: #f2f2f2;
    }
    overflow-y: auto;
    .actions {
        position: relative;
        .ai-icon{
          width: 30px;
          height: 30px;
        }
        .popup {
          position: absolute;
          width: 100px;
          background-color: white;
          z-index: 50;
          
          .contract-card-actions-modal {
            border: 1px solid#1c1d2212;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 80%;
            right: 40px;
            padding: 8px;
            background-color: white;
          }
        
          .contract-card-actions-modal ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
        
          .contract-card-actions-modal li {
            cursor: pointer;
            padding: 5px;
            width: 100%;
            transition: background-color 0.3s ease;
          }
        
          .contract-card-actions-modal li:hover {
            background-color: #f2f2f2;
          }
    
          li:hover {
            background-color: #F5F5F5;
            cursor: pointer;
          }
        }
    
        .plus-icon {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: gray;
          border: 1px solid gray;
          box-shadow: none;
          background-color: white;
        }
    
        .plus-icon:hover {
          background-color: #F5F5F5;
        }
      }
    
      .plus-icon:hover {
        background-color: #F5F5F5;
      }
      width: 100%;
      border-collapse: collapse; 
  
      th,
      td {
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        padding: 12px;
        border: 1px solid #ddd; 
        text-align: left;
        overflow: auto;
      }
      .title-table-item div {
        overflow-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        max-width: 100px; 
      }
  
      th {
        background-color: #f4f4f4;
        font-weight: bold;
        color: #333;
        border-bottom: 2px solid #ddd;
      }
  
      tbody tr {
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #f9f9f9;
        }
        cursor: pointer;
      }
  
      .text-right {
        text-align: right; 
      }
      td:first-child>div {
        display: flex;
        align-items: center;
  
        img {
          width: 25px;
          height: 25px;
        }
      }
      .status {
      background-color: #F5F6F6;
      color: #484A4D;
      border-radius: 7px;
      font-weight: 600;
      padding: 2%;
      text-align: center;
      max-width: -moz-fit-content;
      max-width: fit-content;
      }
      thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    thead {
      position: sticky;
      top: 0;
      z-index: 1
    }
  
    tbody {
      max-height: 400px;
      overflow-y: auto; 
      display: block;
      width: 100%;
    }
  }
  .text-right {
    text-align: right; 
  }
  th{
    display: flex;
    justify-content: space-between;
   
  }
  .thead{
    border-bottom: 1px solid #e9ecef;
  }
  tr{
    border-bottom: 0px !important;
  }
  .table-filters{
    border-top: 0px !important;
    background-color: white !important;
    th{
      overflow: visible;
    }
  }
  .sort-shown{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .sort-hidden{
    display: none;
  }
  th:hover{
    .sort-hidden{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .date-filter-container{
    width: 100%;
    position: relative;
    input{
      padding: 8px !important;
      margin: 0;
      height: 40px;
      border: 1px solid var(--neutral-light) !important;
    }
    .react-datepicker{
    }
  }
  .basic-filter-container{
    width: 100%;
    position: relative;
    input{
      padding: 8px;
      margin: 0;
      height: 40px;
      z-index: 2;
    }
  }
}