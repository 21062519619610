.create-documents-requirement {
    .container-action-requirement {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .container-action-requirement>div {
        margin-right: 25px;
        margin-top: 4%;
    }
    .create-requirement-title{
        font-size: 19px;
        margin-top: 8px;
        font-weight: bold;
    }
}