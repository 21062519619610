.edit-contract-template {
    .set-groups-container{
        position: absolute;
        right: -20px;
        top: 35px;
        border-radius: 0 12px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
    .clause-list-container {
        height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .variable-input-container {
        height: 80vh;
        overflow-y: auto;
    }

    .clause-list-container::-webkit-scrollbar,
    .variable-input-container::-webkit-scrollbar {
        width: 8px;
    }

    .clause-list-container::-webkit-scrollbar-track,
    .variable-input-container::-webkit-scrollbar-track {
        background-color: #f1f1f1 !important;
        border-radius: 4px;
    }

    .clause-list-container::-webkit-scrollbar-thumb,
    .variable-input-container::-webkit-scrollbar-thumb {
        background-color: rgb(204, 202, 205) !important;
        border-radius: 4px;
    }

    .clause-list-container::-webkit-scrollbar-thumb:hover,
    .variable-input-container::-webkit-scrollbar-thumb:hover {
        background-color: var(--neutral-medium);
    }

    .completion-low {
        background-color: var(--ai-error-medium);
        padding: 2px 5px;
        border-radius: 5px;
        color: var(--ai-error-darkest);
    }

    .completion-medium {
        background-color: var(--ai-warning-medium);
        padding: 2px 5px;
        border-radius: 5px;
        color: var(--ai-warning-darkest);
    }

    .completion-high {
        background-color: var(--ai-success-medium);
        padding: 2px 5px;
        border-radius: 5px;
        color: var(--ai-success-darkest);
    }

    .contract-header {
        border-bottom: 1px solid #E5E9F2;
        margin-bottom: 3px;
        height: 35px;
    }

    .contract-tools {
        display: flex;
        gap: 20px;
    }

    .contract-name {
        white-space: nowrap;
    }

    .contract-clause {
        border-left: 1px solid #E5E9F2;
    }

    .contract-clause-header {
        height: 35px;
        margin-bottom: 3px;
        border-bottom: 1px solid #E5E9F2;
    }

    .contract-clause-name {
        white-space: nowrap;
    }

    .edit-action-option {
        margin-top: -5px;
    }

    .content-action-options {
        display: flex;
        margin-left: auto;
        padding-top: 5px;
        margin-bottom: -20px;
    }

    .delete-options {
        margin-left: 10px;
        margin-right: 10px;
        width: 20px;
        height: 20px;
    }

    .add-single-option {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        color: white !important;
        background-color: blue;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px !important;
        margin-top: 5px;
        margin-left: 15px;
    }

    .arg-content-edit {
        padding: 0 !important;
        font-size: 13px;
        margin: 0 !important;
    }

    .custom-textarea-template {
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        width: 100%;
        height: 38px;
        padding-top: -4%;
        padding-left: 15px;
        overflow-wrap: break-word;
        margin-top: 2%;
        margin-bottom: 2%;
    }

    .custom-textarea-template::-webkit-scrollbar {
        width: 8px;
    }

    .custom-textarea-template::-webkit-scrollbar-track {
        background-color: #f1f1f1 !important;
        border-radius: 4px;
    }

    .custom-textarea-template::-webkit-scrollbar-thumb {
        background-color: rgb(204, 202, 205) !important;
        border-radius: 4px;
    }

    .custom-textarea-template::-webkit-scrollbar-thumb:hover {
        background-color: var(--neutral-medium);
    }
    .edit-template-name{
        cursor: pointer;
    }
    .contract-name-input {
        width: 100%;
        padding: 10px 15px;
        margin: 40px 0;
        margin-bottom: 60px;
        font-size: 16px;
        border: 1px solid #ccc; 
        border-radius: 5px;
        outline: none; 
        transition: border-color 0.3s, box-shadow 0.3s; 
      }
      
      .contract-name-input:focus {
        border-color: #ccc;
      }
      .contract-name-input::placeholder {
        color: #999;
        font-style: italic;
      } 
      .formula {
        // margin-left: -10px;
      }
      
      .dropdown-list-formula {
        position: absolute; 
        background-color: #fff; 
        border: 1px solid #ccc;
        border-radius: 4px; 
        max-height: 200px;
        overflow-y: auto; 
        overflow-x: hidden !important;
        z-index: 1000; 
        width: 100%; 
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        padding: 0; 
      
        li {
          padding: 12px 16px; 
          cursor: pointer;
          transition: background-color 0.2s ease; 
      
          &:hover {
            background-color: #f0f8ffb5; 
            color: #2F14E5; 
          }
      
          &:active {
            background-color: #e0e0e0;
          }
        }
      }
      
      .dropdown-list-formula {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
      
        &.visible {
          opacity: 1;
          transform: translateY(0); 
        }
      }
      .error {
        color: red;
        margin-top: 10px;
      }
      .custom-form-textarea{
        border: 1px solid var(--neutral-light);
        border-radius: 4px;
      }
}