.templates {
    .template-table{
        .actions {
            position: relative;
            .ai-icon{
              width: 30px;
              height: 30px;
            }
            .popup {
              position: absolute;
              width: 100px;
              background-color: white;
              z-index: 50;
              
              .contract-card-actions-modal {
                border: 1px solid#1c1d2212;
                border-radius: 4px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                position: absolute;
                top: 80%;
                right: 40px;
                padding: 8px;
                background-color: white;
                width: fit-content;
                left: -30px;
              }
            
              .contract-card-actions-modal ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
              }
            
              .contract-card-actions-modal li {
                cursor: pointer;
                padding: 5px;
                width: 100%;
                transition: background-color 0.3s ease;
              }
            
              .contract-card-actions-modal li:hover {
                background-color: #f2f2f2;
              }
        
              li:hover {
                background-color: #F5F5F5;
                cursor: pointer;
              }
            }
        
            .plus-icon {
              width: 22px;
              height: 22px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: gray;
              border: 1px solid gray;
              box-shadow: none;
              background-color: white;
            }
        
            .plus-icon:hover {
              background-color: #F5F5F5;
            }
          }
        
          .plus-icon:hover {
            background-color: #F5F5F5;
          }
          width: 100%;
          border-collapse: collapse; 
      .icon{
        margin-right: 10px;
      }
      th,
      td {
        word-wrap: break-word;
        white-space: normal;
        padding: 12px;
        border: 1px solid #ddd; 
        text-align: left;
      }
      td {
        overflow-wrap: break-word;
            white-space: normal;
      }
      .title-table-item div {
        overflow-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        max-width: 100px; 
      }
      
          th {
            background-color: #f4f4f4;
            font-weight: bold;
            color: #333;
            border-bottom: 2px solid #ddd;
          }
      
          tbody tr {
            transition: background-color 0.3s ease;
      
            &:hover {
              background-color: #f9f9f9;
            }
            cursor: pointer;
          }
      
          .text-right {
            text-align: right; 
          }
          td:first-child>div {
            display: flex;
            align-items: center;
      
            img {
              margin-right: 10px;
              width: 25px;
              height: 25px;
            }
          }
          .status-active{
            border-radius: 7px;
            font-weight: 600;
            padding: 2%;
            text-align: center;
            max-width: -moz-fit-content;
            max-width: fit-content;
          }
          .status {
            background-color: #F5F6F6;
          color: #484A4D;
          border-radius: 7px;
          font-weight: 600;
          padding: 2%;
          text-align: center;
          max-width: -moz-fit-content;
          max-width: fit-content;
          }
          thead, tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;
          }
        thead {
          position: sticky;
          top: 0;
          z-index: 1;
        }
      
        tbody>div {
          max-height: 400px;
          overflow-y: auto; 
        }
        
    }
    width: 100%;
    .current-pathname {
        cursor: pointer;
        font-weight: bold;
        font-size: 13px;
        border: 1px solid #E5E9F2;
        padding: 5px 10px;
    }
    h1 {
        font-weight: 700;
        color: var(--neutral-darker);
        font-size: 16px;
    }

    .search-bar {
        position: relative;
        display: flex;
    }

    .search-icon {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    input[type="text"] {
        padding-left: 30px;
        text-decoration: none;
        /* Adjust the left padding to accommodate the search icon */
    }

    .search-input {
        height: 48px;
        width: 408px;
        border-radius: 8px;
        border: 1px solid var(--neutral-light);
    }

    .search-zone {
        width: 641px;
    }

    .filter>div:first-child {
        font-weight: 700;
        font-size: 12px;
        color: var(--neutral-medium);
    }

    .filter>div:nth-child(2) {
        color: var(--primary-color-darker);
        font-weight: 400;
        font-size: 14px;
    }

    .templates-space {
        border: 2px dashed var(--neutral-light);
        min-height: 55vh !important;
        border-radius: 15px;
        margin-bottom: 10px;
        width: 30%;
    }

    .templates-space-title {
        font-family: "Hanken Grotesk";
        font-style: normal;
        font-weight: 500;
        font-size: 20.7436px;
        color: var(--neutral-medium);
    }

    .templates-per-level {
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.templates-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
}