.admin-ressource-card-container:hover{
  border: 1.5px solid #ECECED;
  cursor: pointer;
}
.admin-ressource-card-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ECECED;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  height: fit-content;

  .header {
    padding: 20px;
    min-height: 65px;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    gap: 5px;
    .title {
      display: flex;
      flex-direction: row;
      padding-top: 5px;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .name {
        font-size: 16px;
        font-weight: 400;
        color: #2B2E31;
        margin-left: 10px;
        margin-top: 2px;
        word-break: break-all;
      }
    }

    .actions {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      .ai-icon{
        width: 35px;
        height: 35px;
      }
      .popup {
        position: absolute;
        width: 100px;
        background-color: white;
        
        .contract-card-actions-modal {
          border: 1px solid#1c1d2212;
          border-radius: 4px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          position: absolute;
          top: 100%;
          right: 0;
          padding: 8px;
          background-color: white;
          width: fit-content;
          left: -30px;
        }
      
        .contract-card-actions-modal ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
      
        .contract-card-actions-modal li {
          cursor: pointer;
          padding: 5px;
          width: 100%;
          transition: background-color 0.3s ease;
        }
      
        .contract-card-actions-modal li:hover {
          background-color: #f2f2f2;
        }

        li:hover {
          background-color: #F5F5F5;
          cursor: pointer;
        }
      }

      .plus-icon {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: gray;
        border: 1px solid gray;
        box-shadow: none;
        background-color: white;
      }

      .plus-icon:hover {
        background-color: #F5F5F5;
      }
    }
  }

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    .timestamp{
      div{
        gap: 5px;
        display: flex;
        .value{
        font-weight: 400;
        color: #484A4D;
        font-size: 12px;
        word-break: break-all;
      }
      .label{
        font-weight: 500;
        color: #2B2E31;
        font-size: 12px;
      }
      }
      
    }
    .body-section {
      flex: 1;
      display: flex;
      gap: 5px;
      .label {
        font-weight: 600;
        color: #2B2E31;
        font-size: 14px;
        flex: 1;
        // disable break lines
        white-space: nowrap;
      }

      .value {
        font-weight: 400;
        color: #484A4D;
        font-size: 14px;
        word-break: break-all;
      }
    }
  }

  .footer {
    max-height: 61px;
    min-height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ECECED;

    .user-name {
      color: #2B2E31;
      font-size: 14px;
      font-weight: 400;
    }
    .users-arabic-display{
      right: 30% !important;
    }
    .users-display {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
    }

    .avatar-circle {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      border: 2px solid #1c1d2212;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-right: -10px;
    }

    .avatar-circle p {
      margin: 0;
      font-size: 14px;
      font-weight: normal !important;
      color: white;
      z-index: 3;
    }

    .layer-1 {
      left: 48px;
      z-index: 2;
      background-color: rgb(162, 162, 252);
    }

    .layer-2 {
      left: 30px;
      z-index: 1;
      background-color: rgb(66, 66, 245);
    }

    .layer-3 {
      left: 56px;
      z-index: 3;
    }

    .layer-3>p {
      color: black;
    }

    .status {
      background-color: #f6f6f5;
      border: 1px solid #eeeee8;
      color: #484A4D;
      margin: 5px 20px;
      padding: 3px 10px;
      border-radius: 10px;
      font-weight: 600;
    }
    .status-active{
      background-color: #e5f9d5;
      border: 1px solid #d6f6bc;
      color: #484A4D;
      margin: 5px 20px;
      padding: 3px 10px;
      border-radius: 10px;
      font-weight: 600;
    }
    .loading{
      background-color: #f6f6f5;
      border: 1px solid #eeeee8;
      color: #484A4D;
      margin: 5px 20px;
      padding: 3px 10px;
      border-radius: 10px;
      font-weight: 600;
      max-height: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100px;
    }
  }
}