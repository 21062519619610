/* update element input progress */
.input-inprogress {
  border-radius: 800px;
  background: #fff4e7;
  padding: 2px 5px;
  font-weight: 400;
  font-size: 12px;
  height: 25px;
  min-width: 88.47px;
  text-align: center;
  color: #212529;
}
.boolean-group{
  gap:35px !important;
  align-items: center !important;
}
/* change all input type text  */
.input-inprogress + input {
    color: black !important;
    font-size: 12.9px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 19.64px !important;
    padding: 0% !important;
}
/* change all input type date */
.react-datepicker__input-container  > input{
padding: 0% !important;
font-size: 12.9px !important;
}
/* change all input type file */

.input-type-file{
padding: 0% !important;
font-size: 12.9px !important;
margin: 0% !important;

}
.edit-param-container{
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  .edit-param-name{
    width: 100%;
    display: flex;
    align-items: center;
    .label{
  // dont break line
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 70px;
      max-width: 100px;
    }
    .input{
      padding: 4px;
      max-width: 400px;
      max-height: 30px;
    }
  }
  .error{
    color: red;
    font-size: 12px;
  }
}


/* update element input done */
.input-done {
  padding: 2px 5px;
  min-width: 88px;
  font-weight: 700;
  font-size: 12px;
  width: 88.47px;
  height: 25px;
  color: #0fca7a;
  text-align: center;
  letter-spacing: 0.1px;
  border-radius: 800px;
  border: 1px solid #e5f9f0;
  background: #E5F9F0;
}
/* update form check label */
.form-check-label {
  color: #1f2b3a;
  font-size: 11.9px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.64px;
}
/* update radio button */
.form-check-input {
  background-color: #ede5fc;
  border-color: #ede5fc;
}
.form-check-input:checked[type="radio"] {
  background-color: #ede5fc;
  border-color: #ede5fc;
  background-image: url("https://www.publicdomainpictures.net/pictures/310000/nahled/blue-circle.png");
  background-size: 9.5px 9.5px;
  background-position: 2.5px;
}
.boolean-check{
  width: 15px;
  height: 15px;
}
.checked-input{
  background-image: url("https://www.publicdomainpictures.net/pictures/310000/nahled/blue-circle.png");
  background-size: 9.5px 9.5px;
  background-position: 2.5px;
}
.boolean-check:checked[type="radio"] {
  background-size: 8.5px 8.5px !important;
  background-position: 2.5px !important;
}

.input-error {
  background-color: var(--ai-error-clear);
  color: var(--ai-success-darkest);
  font-weight: 700;
  font-size: 12px;
  height: 25px;
  min-width: 70px;
  text-align: center;
}
/* update boolean type into checkbox */
.segmented-control {
  display: flex;
}
.segment {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #e5e9f2;
  flex: 1;
  min-width: 40px;
  position: relative;
  background-color: #e5e9f2;
}

.segment.active {
  background-color: #7014e5;
}
.segment.segment.active::after {
  background: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
}
.checkbox-beneficial{
  display: flex;
  margin-left: 15px;
  margin-bottom: 5px;
}
.checkbox-beneficial > label{
  color: #1f2b3a;
  font-weight: 400;
  margin-left: 8px;
}
.delete-single-option{
  margin-left: 10px;
  width: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.param-list-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.label-input-edit{
  font-size: 13px;
  padding: 5px;
  width: 90%;
  height: 40px ;
  padding-left: 10px;
  overflow-wrap: break-word;
  border: 1px solid#d3d2da;
}
.add-enum-single-option{
  border-radius: 8px;
  background: var(--primary-color);
  color: white; 
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}
.pointer{
  cursor: pointer;
}
.label-input-edit:focus{
  border-color: gray;
}
.option-list-edit{
  height: 30px;
  margin-top: 15px;
  margin-right: 15px;
}
.container-table-element{
  width: 90%;
  align-self: center;
  justify-content: space-between; 
}
.header-table-edit{
  color: rgb(41, 41, 41);
  text-align: justify;
  text-align: start;
  width: 40%;
  font-size: 14px;
}
.container-table-element > p{
  margin-bottom: 3px;
  text-align: start;
  width: 40%;
  font-size: 14px;
}
.param-type{
  padding: 2px 5px;
  width: fit-content !important;
  font-weight: 700;
  font-size: 12px;
  color: #333333;
  text-align: center;
  letter-spacing: 0.1px;
  border-radius: 800px;
  border: 1px solid #eaefed;
  background: #eaefedb0;
}
.param-current{
  padding: 2px 5px;
  width:fit-content !important;
  border-radius: 800px;
  border: 1px solid #eaefed;
  background: #eaefedb0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.param-current-name{
  font-weight: 700;
  font-size: 12px;
  color: #333333;
  text-align: center;
  letter-spacing: 0.1px;
}
.param-type-list{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  margin-top: 5px;
}
.param-type-list-item{
  padding: 2px 5px;
  width: fit-content !important;
  font-weight: 700;
  font-size: 12px;
  color: #333333;
  text-align: center;
  letter-spacing: 0.1px;
  border-radius: 800px;
  border: 1px solid #eaefed;
  background: #f4f7f6b0;
}
.param-type-list-item:hover{
  background: #eaefedb0;
}
.rotate-expand-active{
  transform: rotate(180deg);
  transition: ease-in-out 0.3s;
  display: block;
}
.rotate-expand{
  transition: ease-in-out 0.3s;
  display: block;
}

.date-disabled{
  margin: 12px 0px;
  color: gray;
  font-size: 14px;
}

.template-param-list{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  list-style: none;
  padding-top: 10px;
}



.subclause-param-list{
  margin-top: 20px;
}
.subclause-param-list{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 10px;
}