.action-import-typeLevels{
    padding: 10px;
    width: 28vh;
}
.actions-import-types{
    position: absolute;
    bottom:2%;
}
.table-container-types {
    width: 100%;
    overflow-x: auto;
    max-height: 48vh;
  }
  
  .fixed-header-table-types {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed; 
  }
.header-table-types{
font-size: 75%;
}
.data-table-types{
  font-size: 12.5px;
}