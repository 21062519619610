.upload-document-signed{
  width: 100% !important;
  border-radius: 6px !important;
    background: #2F14E5 !important;
    color: #fff !important;
    text-align: center !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    letter-spacing: 0.5px;
    margin-top: 18px;
}