.level-types {
    background-color: #EEE3FD;
    width: fit-content;
    padding: 5px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
    max-width: fit-content;
}
.ressources-filters{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.filter-item{
    width: 250px;
}
.search-container{
    width: 300px;
    position: relative;
}
.lookup-img{
    top: 25px;
}
.lookup-img-arabic{
    margin-right: 85%;
}
.filter-type-item{
    display: flex;
}
.groups-project{
    position: absolute;
    top: 50px;
    z-index: 50;
    right: 5%;
}
.project-template-title {
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px;
    position: relative;
    .project-template-title-details {
      display: flex;
      align-items: left;
      flex-direction: column;

      .title {
        color: #2B2E31;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        align-items: center;
        .input-title{
          font-size: 16px;
          font-weight: 600;
          color: #2B2E31;
          border-radius: 8px;
          border: 1px solid #D9D9D9;
          width: 350px;
        }
      }

      .modified {
        color: #8D8E90;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
.projectTemplate{
    .admin-ressource-card-container{
        margin-left: 17px;
    }
    .project-title {
        color: #363540 !important;
        font-size: 20px;
        font-weight: bold;
    }

    .document-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3%;
        margin-bottom: 3%;
        max-height: 500px;
        overflow-y: scroll;
        .admin-ressource-card-container{
            width: 350px;
        }

        .card-document {
            position: relative;
            background-color: white;
            min-height: 265px;
            height: fit-content;
            width: 320px;
            border-radius: 8px;
            border: 2px solid #1c1d2212;
            margin-right: 15px;
            margin-top: 12px;
            padding-right: 8px;
        }

        .card-document:hover {
            cursor: pointer;
        }

        .create-document,
        .see-document {
            font-weight: 700;
            font-size: 12px;
            color: var(--primary-color-medium);
            cursor: pointer;
            background: rgba(172, 171, 186, 0.1);
            border-radius: 15.4744px !important;
            width: 122.13px;
            height: 40px;
            text-align: center;
            border: 0 !important;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        }

        .create-document {
            position: absolute;
            bottom: 15px;
            width: 130px;
        }

        .document-name {
            font-weight: 900;
            font-size: 20px;
            color: var(--neutral-dark);
            letter-spacing: -0.02em;
            margin-top: 15px;
        }

        .document-id {
            font-weight: 500;
            font-size: 17px;
            color: var(--neutral-light);
        }

        .document-status {
            font-weight: 700;
            font-size: 16px;
            color: var(--neutral-darker);
            display: flex;
            justify-content: space-between;
            width: 90%;
            align-items: flex-start;
        }

        .document-date,
        .document-creator {
            display: flex;
            justify-content: space-between;
            width: 90%;
            align-items: flex-start;
        }

        .document-date>span,
        .document-creator>span,
        .document-status>span {
            width: 46%;
        }

        .document-type {
            color: #d3d2da;
        }

        .actions-document {
            position: absolute;
            bottom: 15px;
            width: 90%;
            display: flex;
        }
        .rounded-circle {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
            border: 2px solid #1c1d2212;
            background-color: white;
        }
        .contract-card-actions-modal {
            border: 1px solid #1c1d2212;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 100%;
            right: 0;
            padding: 8px;
            background-color: white;
            width: fit-content;
            left: -30px;
        }
        .contract-card-actions-modal ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
        .contract-card-actions-modal li {
            cursor: pointer;
            padding: 5px;
            width: 100%;
            transition: background-color 0.3s ease;
        }
        .contract-card-actions-modal li:hover {
            background-color: #f2f2f2;
          }
          p {
            font-weight: 700;
            font-size: 13px;
            color: var(--neutral-dark);
            max-width: 832px;
        }
    }
}