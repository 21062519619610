.create-project {
  .create-project-title {
      color: #363540 !important;
      font-size: 20px;
  }

  .label-form-create-project {
      color: #363540 !important;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
  }

  .form-project-items {
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
  }

  .form-project-items>div {
      width: 49%;
      border: 1px solid #ACABBA;
      padding: 1%;
      border-radius: 4px;
  }

  .form-project-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
  }

  .form-project-item>div {
      width: 100%;
      margin-top: -1%;
      margin-bottom: 2%;
  }

  .form-project-item .custom-combobox-container {
      border: 1px solid var(--neutral-light) !important;

  }

  .form-project-item .custom-combobox-label {
      font-weight: 500 !important;
  }

  .form-project-item>input:focus,
  .form-project-item>textarea:focus {
      outline: none;
  }

  .project-description {
      border: 1px solid var(--neutral-light);
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: auto;
      width: 100%;
      min-height: 100px;
      max-height: 150vh;
      padding-left: 5%;
      padding-top: 2%;
      overflow-wrap: break-word;
  }

  .form-project-items .item-description {
      color: #ACABBA;
      font-weight: 500;
      font-size: 15px;
  }

  .project-beneficial-name {
      position: relative;
  }

  .list-beneficial-project {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2000;
      border-radius: 12px;
      border: 1px solid #E5E9F2;
      background: #FFF;
      box-shadow: 0px 3px 12px 1px rgba(23, 12, 47, 0.15);
      width: 100%;
      font-style: normal;
      font-weight: 500;
      line-height: 22.8px;
      padding-left: 4%;
      padding-top: 2%;
      max-height: 200px;
      overflow-y: auto;
  }

  .item-beneficial-project {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--neutral-light);
      padding: 8px;
  }

  .beneficial-project-img,
  .selected-beneficial-project-img {
      display: block;
      max-width: 30px;
      max-height: 30px;
      border-radius: 50%;
  }

  .selected-beneficial-project-img {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
  }

  .add-new-project-beneficial {
      width: fit-content;
      margin: auto;
      text-align: center;
      color: blue;
  }

  .add-new-project-beneficial:hover {
      border-bottom: 1px solid blue;
      cursor: pointer;
  }

  .label-beneficial {
      color: #363540 !important;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
  }

  .templates-content {
      border: 1px solid #ACABBA;
      border-radius: 4px;
      padding-left: 15px;
      padding-top: 10px;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .create-project {
          width: 180px;
          height: 50px;
          background-color: var(--primary-color-medium);
          color: white;
          font-size: 14px;
          font-weight: 700;
          box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02), 0px 13px 8px rgba(51, 18, 239, 0.07), 0px 6px 6px rgba(51, 18, 239, 0.12), 0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
          border-radius: 10px !important;
          border: none;
          margin-left: 80%;
      }

      .template-cards-wrapper {
          display: flex;

          .card-template {
              position: relative;
              border: 2px solid #E3EBF6;
              box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.04);
              background-color: white;
              min-height: 250px;
              height: fit-content;
              min-width: 300px;
              border-radius: 8px;
              margin-right: 16px;
              padding-right: 20px;
          }

          .card-template:last-child {
              margin-right: 0;
          }

          .card-template .selected-req {
              border-color: #2F14E5;
          }

          .not-selected-req {
              border: 2px solid #E3EBF6;
          }

          .see-template {
              color: #2F14E5;
              border: none !important;
              box-shadow: none !important;
              position: absolute;
              bottom: 0px;
              right: 15px;
          }

          .template-reqs {
              margin-bottom: 25px;
              display: flex;
              justify-content: space-between;
          }

          .req-content {
              border: 1px solid;
              border-color: #2F14E5;
              box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.04);
              background-color: white;
              min-height: 150px;
              height: fit-content;
              width: 200px;
              border-radius: 8px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5px;
              margin-right: 16px;
              cursor: pointer;
          }

          .req-content>span {
              text-wrap: wrap;
          }

          .not-selected-req {
              border-color: #d2dbe7;
              box-shadow: none;
          }
      }

      .container-templates {
          display: flex;
          overflow-x: auto;
          white-space: nowrap;
          max-width: 130vh;
          margin-bottom: 15px;
          margin-left: 15px;
      }

      ::-webkit-scrollbar {
          width: 6px;
          height: 4px;
      }

      ::-webkit-scrollbar-thumb {
          background-color: #888;
          border-radius: 6px;
      }

      ::-webkit-scrollbar-track {
          background-color: #f0f0f0;
      }

      /* Firefox */
      ::-moz-scrollbar {
          width: 6px;
      }

      ::-moz-scrollbar-track {
          background: #f0f0f0;
      }

      ::-moz-scrollbar-thumb {
          background: #888;
          border-radius: 6px;
      }

      .sub-header-content {
          color: #ACABBA
      }

      .header-content {
          font-size: 20px;
      }
  }
  .lookup-img-arabic{
      left: 15px;
      right: auto;
    }
    .selected-beneficial-project-img-arabic{
      right: 90% !important;
    }
}