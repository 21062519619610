.form-page {
    .contract-name {
        font-weight: 900;
        font-size: 31px;
        color: var(--neutral-dark);
        letter-spacing: -0.02em;
    }

    .contract-container h4 {
        font-weight: 900;
        font-size: 23px;
        color: var(--neutral-medium);
    }

    .contract-container h5 {
        font-weight: 700;
        font-size: 15px;
    }

    .contract-container p {
        font-weight: 400;
        font-size: 14px;
        color: var(--neutral-medium);
    }

}