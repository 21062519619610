.ressource-options{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.ressource-option{
border-radius: 8px;
min-width: 200px;
width: fit-content;
height: 150px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
border: 1.5px solid #ECECED;
border-radius: 16px;
padding-left: 15px;
padding-right: 15px;
text-align: center;
font-size: 17px;
font-weight: bold;
display: flex;
justify-content: center;
align-items: center;
margin-left: 18px;
cursor: pointer;
}