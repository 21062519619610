.fallBack-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
  padding: 20px;


.error-title {
  font-size: 2rem;
  color: #dc3545;
}

.error-message {
  font-size: 1.2rem;
  margin: 20px 0;
}

.retry-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.retry-button:hover {
  background-color: #0056b3;
}
}