.table-wrapper {
  position: relative;
  max-height: 450px; 
  overflow-y: auto;
  border: 1px solid #ddd;
  margin-top: 20px; 

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;

    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: #f9f9f9; 
      z-index: 10; 
    }

    th {
      background-color: #e9ecef;
      padding: 0.75rem;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }

    tbody {
   

      td {
        padding: 0.75rem;
        border-bottom: 1px solid #ddd;
      }
    }
  }
}
.error-msg{
  color: red;
  font-size: 14px;
  margin-bottom: 10%;
  margin-top: 5px;
}
.unmatched-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.unmatched-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 480px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 10px;
    color: #333;
  }

  p {
    font-size: 14px;
    color: #666;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 20px;
    list-style-type: disc;

    li {
      font-size: 14px;
      color: #444;
    }
  }

  .popup-actions {
    display: flex;
    justify-content: space-between;
  }
.unmatched-list{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  li{
    display: flex;
    justify-content: space-between;
    margin: auto;
  }
  .unmatched-item{
    max-width: 300px;
    word-wrap: break-word; 
    word-break: break-all; 
    overflow-wrap: break-word; 
  }
  margin-bottom: 25px;
}
  .btn-match,
  .btn-ignore,
  .btn-close {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .btn-match {
    color: #b3b3b3;
    background-color: #f0f0f0 !important;
    box-shadow: none;

  }

  .btn-ignore {
    background-color: var(--primary-color-medium);
    color: white;
  }
  .fail-icon {
    font-size: 1rem;
    color: #e74c3c;
    margin-right: 1rem;
  }

  .btn-close {
    background-color: #f44336;
    color: white;
  }

  .btn-match:hover,
  .btn-ignore:hover,
  .btn-close:hover {
    opacity: 0.8;
  }
}
