.notification-popup {
  position: absolute;
  top: 120%;
  right: 5px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  width: 450px;
  overflow-y: auto;
  border-radius: 8px;
  padding: 5px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  div {
    padding: 8px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
    font-size: 13px;
  }

  div:last-child {
    border-bottom: none;
  }

  .notification-time {
    font-size: 12px;
    color: #888;
  }

  .notification-message {
    cursor: pointer;
  }

  .no-notifications-message {
    font-size: 12px !important;
    color: #888 !important;
    font-weight: 400 !important;
    text-align: center;
  }

  .notification-not-seen {
    background-color: #EEE3FD;
  }
}
.notification-popup-arabic{
  right: -415px !important;
}