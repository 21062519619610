.exchange-modal {
  * {
    opacity: 1 !important;
  }
  .deleted-text {
    position: relative;
    cursor: pointer; /* Indicates interactivity */
    color: red; /* Style the placeholder */
  }
  
  .deleted-text::before {
    content: "..."; /* Placeholder content */
    color: inherit;
  }
  
  .deleted-text:hover::after {
    content: attr(data-full-text); /* Display full text on hover */
    position: absolute;
    top: 100%; /* Position below the placeholder */
    left: 0;
    background-color: #fefefe;
    color: #000;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    white-space: nowrap; /* Prevent wrapping */
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .exchange-type {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    height: 40px;
  }

  .exchange-type-item {
    width: 100%;
    font-size: 16px;
    height: 40px;
    font-weight: 500;
    border-radius: 8px;
    border: 2px solid #2F14E5;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d6d1f4;
  }

  .exchange-type-item-active {
    background-color: #2F14E5;
    color: white;
  }

  .modal-actions{
    display: flex;
    justify-content: center
  }

  .clause-comparison-modal-content {
    width: 90% !important;
    background-color: white !important;
    border-radius: 10px !important;
    cursor: auto;
    height: 80% !important;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .body-comparison {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex: 1;
      display: flex;
      font-size: 15px;
      padding: 10px !important;
    }

    .clause-comparison-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      flex: 1;
      overflow: hidden;

      .clause-exchange-current {
        flex: 1;
      }

      .clause-exchange-found {
        gap: 10px;
        flex: 1;
        padding-bottom: 0;
        border-radius: 10px;
        overflow-x: visible;
        display: flex;
        flex-direction: column;
      }
    }

    .clause-comparison-current {
      border: 2px dashed #D9D9D9;
      border-radius: 10px;
      padding: 13px;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
    }

    .clause-comparison-chosen {
      border: 2px dashed #D9D9D9;
      border-radius: 10px;
      padding: 13px;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }

    .clause-comparison-choice {
      max-height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .clause-comparison-cummon-title {
      color: var(--secondary-color-darker, #300962);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.24px;
    }

    .clause-comparison-actual-title {
      color: var(--secondary-color-darker, #300962);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.4px;
      position: relative;
      display: inline;
      padding-bottom: 8px;
    }

    .clause-comparison-actual-title::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      border-bottom: 4px solid #7014E5;
    }

    .clause-comparison-body {
      flex: 1;
      overflow-y: auto;
      font-size: 15px;
    }

    .clause-comparison-body-select {
      overflow-y: auto;
      flex: 1;
    }

    .clause-comparison-header {

    }

    .clause-comparison-select-container {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 18px;
      overflow-x: auto;
      white-space: nowrap;
      padding: 0 10px;
      margin-bottom: 20px;
    }

    .clause-comparison-select-element {
      flex: 0 0 auto;
      padding: 5px 10px 8px 10px;
      justify-content: center;
      align-items: center;
      border-radius: 800px;
      border: 1px solid #E5E9F2;
      background: #E5E9F2;
      cursor: pointer;
      color: #000;
    }

    .clause-comparison-select-element-text {

      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.26px;
      margin: auto;
    }

    .selected-comparison {
      color: white !important;
      background-color: #2F14E5 !important;
    }

    .confirm-comparison-button {
      border-radius: 5px;
      border: 1px solid #2F14E5;
      background: #2F14E5;
      color: #FFF;
      text-align: center;
      float: right;
      margin-top: 50px;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.26px;
      width: 171px;
      height: 36px;
      padding: 9px 10px;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      bottom: 5px;
      right: 0;
    }

    .lookup-img-arabic {
      left: 15px;
      right: auto;
    }

    .x-img {
      position: absolute;
      top: 12px;
      right: 40px;
      cursor: pointer;
      color: red;
    }

    .x-img-arabic {
      right: auto;
      left: 40px;
    }

    .disabled-confirm-button {
      background: #E5E9F2;
      color: #000;
      border: 1px solid #E5E9F2;
      cursor: not-allowed;
    }
  }

  .list-beneficial-project {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2000;
    border-radius: 12px;
    border: 1px solid #E5E9F2;
    background: #FFF;
    box-shadow: 0px 3px 12px 1px rgba(23, 12, 47, 0.15);
    width: 100%;
    font-style: normal;
    font-weight: 500;
    line-height: 22.8px;
    padding-left: 4%;
    padding-top: 2%;
    max-height: 200px;
    overflow-y: auto;
  }


  .item-beneficial-project {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--neutral-light);
    padding: 8px;

    .item-template-name {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.8px;
      letter-spacing: 0.28px;
      color: var(--secondary-color-darker, #300962);

      .item-template-name-seperator {
        opacity: 0.8;
      }
    }
  }

  .project-beneficial-name {
    position: relative;
    height: 60px;
  }

  .confirm-button {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}