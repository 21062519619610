.reglement-modal{
  .suggestions{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
  }
  .suggest-add{
    cursor: pointer;
    background-color: #d6d1f4;
    flex: 1;
    max-width: 20%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    font-size: 15px;
    border: 1px solid #AA9FF4;
    color: #707274;
  }
  .suggest-card{
    flex: 1;
    max-width: 20%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    background-color: white;
    font-size: 15px;
    border: 1px solid #AA9FF4;
    color: #707274;
  }
}