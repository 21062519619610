.add-tag{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #2F14E5;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
  transition: all 0.1s;
}

.add-tag:hover{
  cursor: pointer;
  background-color: rgb(243, 241, 241);
  transition: all 0.1s;
}

.nested-param-list{
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
.nested-param{
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #ECECED;
  margin-bottom: 10px;
}
}