.add-clause {
  transition: all 0.5s ease;
  border-radius: 12px;
  box-shadow: 1px 1px 1px 1px #e5e9f2, -1px -1px 1px 1px #e5e9f2;

  .add-clause-tag {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #556581;
      cursor: pointer;
  }
  .add-clause-form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #556581;
      gap: 20px;

      .add-clause-name {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 10px;

          .add-clause-name-label {
              min-width: 100px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
          }

          .add-clause-name-input {
              height: 25px;
              width: 30vw;
          }
      }

      .add-clause-actions {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .add-clause-button{
              width: 70px;
          }
          .select-clause-button{
            width: 120px;
          }
      }
  }
  .error-message{
    color: red;
    font-size: 15px;
    margin-left: 8px;
  }
}

.add-clause:hover {
  display: block;
  transition: all 0.5s ease;

  .add-clause-tag {}
}