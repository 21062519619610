.showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slideshow-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 6px;
    margin-top: 20px;
}

.bar-item {
    width: 27.97px;
    height: 5.99px;
    background: rgba(47, 20, 229, 0.25);
    transition: background 0.5s ease-in-out;
    border-radius: 3px;
}

.bar-active {
    width: 47.94px;
    background: #2f14e5;
}

.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.showcase img {
    height: 260px;
    margin-left: -50px;
}

.slideshow-text {
    font-family: "Hanken Grotesk";
    font-style: normal;
    font-weight: 400;

    line-height: 22px;
    /* or 150% */

    text-align: center;

    color: rgba(0, 0, 0, 0.85);
}
