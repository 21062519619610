.carousel-container {
  flex: 1;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  .fading-enter {
    opacity: 0;
    transform: scale(0.9);
}

.fading-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.fading-exit {
    opacity: 0;
}

.fading-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}


.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}
.slide-exit {
  transform: translateX(0%);
  display: none !important;
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
  display: none !important;
}
  .background-video {
    position: absolute;
    top: 5;
    left: 0;
    width: 97%;
    height: 95%;
    object-fit: cover;
    z-index: 1;
    border-radius: 20px;
  }
  .arabic{
    left: 0 !important;
    right: auto !important;
  }
  .vid-content {
    position: absolute;
    bottom: 30px;
    left: 0;
    color: white;
    text-align: center;
    z-index: 2;
    width: 95%;
    height: 30%;
    .content{
      justify-content: left;
      display: flex;
      flex-direction: column;
      .arabic{
        text-align: right !important;
        justify-content: right !important;
      }
      .title{
        font-weight: 600;
        font-size: 3rem;
        justify-content: left;
        display: flex;
        padding-left: 50px;
        text-align: left;
      }
      .description{
        font-size: 1.8rem;
        font-weight: 400;
        justify-content: left;
        display: flex;
        padding-left: 50px;
        word-wrap: break-word;
        text-align: left;
      }
    }
    .navigation{

    }
  }
}