.reglements {
  width: 100%;
  .current-pathname {
      cursor: pointer;
      font-weight: bold;
      font-size: 13px;
      border: 1px solid #E5E9F2;
      padding: 5px 10px;
  }
  h1 {
      font-weight: 700;
      color: var(--neutral-darker);
      font-size: 16px;
  }

  .search-bar {
      position: relative;
      display: flex;
  }

  .search-icon {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
  }

  input[type="text"] {
      padding-left: 30px;
      text-decoration: none;
      /* Adjust the left padding to accommodate the search icon */
  }

  .search-input {
      height: 48px;
      width: 408px;
      border-radius: 8px;
      border: 1px solid var(--neutral-light);
  }

  .search-zone {
      width: 641px;
  }

  .filter>div:first-child {
      font-weight: 700;
      font-size: 12px;
      color: var(--neutral-medium);
  }

  .filter>div:nth-child(2) {
      color: var(--primary-color-darker);
      font-weight: 400;
      font-size: 14px;
  }

  .templates-space {
      border: 2px dashed var(--neutral-light);
      min-height: 55vh !important;
      border-radius: 15px;
      margin-bottom: 10px;
      width: 30%;
  }

  .templates-space-title {
      font-family: "Hanken Grotesk";
      font-style: normal;
      font-weight: 500;
      font-size: 20.7436px;
      color: var(--neutral-medium);
  }

  .templates-per-level {
      max-height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;
  }
}

.templates-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}