body.custom-fonts {
    font-family: "Open Sans", sans-serif;
}

body.custom-fonts h1,
body.custom-fonts h2,
body.custom-fonts h3,
body.custom-fonts h4,
body.custom-fonts h5,
body.custom-fonts h6 {
    font-family: "Hanken Grotesk", sans-serif;
}
