.constraints-container{

  .btn-symbol{
    border-radius: 50%;
    padding: 10px;
    background-color: #f0f8ffb5;
    border: 1px solid #2F14E5;
    width: 20px;
    height: 20px;
    margin-top: 14px;
    }
}
.constraint-container{
  margin-bottom: 10px;
  .inputs{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 90%;
  }
  .custom-form-textarea{
    padding: 0 0 0 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .label{
    max-width: 100px;

  }
  .formula{
    flex: 1;
  }

}