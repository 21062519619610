.navbar-custom {
    border-bottom: 1px solid #E5E9F2;
    height: 12vh;
    background-color: white;
    z-index: 4;
    /* update save button css */
.save-button {
  width: 150px;
  height: 48px;
  border-radius: 10px !important;
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  transition: background-color 0.3s ease;
  box-shadow: none !important;
  border: none;
}

.disabled {
  background-color: var(--neutral-medium);
  transition: background-color 0.3s ease;
}

/* deleted previous css */

/* update save button and audit button css */
.save-button-changes {
  border-radius: 800px !important;
  border: 1px solid #E5E9F2 !important;
  background: #E5E9F2 !important;
  color: #000;
  text-align: center !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  display: flex;
  width: 170px;
  height: 45px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.26px !important;
  box-shadow: none !important;
  transition: background-color 0.3s ease;

}

/* update save draft button */
.save-button-draft {
  border-radius: 800px !important;
  border: 1px solid #7014E5 !important;
  background: #7014E5;
  display: flex;
  width: 220px !important;
  height: 45px !important;
  padding: 10px;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.26px !important;
  box-shadow: none !important;
  transition: background-color 0.3s ease;
  color: white !important;
  font-size: 15px !important;

}
.navbar-buttons{
  max-width: 1200px;
  flex-wrap: wrap;
  margin: 5px;
}
.navbar-button {
  padding: 10px  !important;
  border-radius: 800px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #2F14E5;
  color: #FFF;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.26px;
  margin-top: 5px;

}

@media (max-width: 1250px) {
  .navbar-button {
    padding: 6px  !important; 
    font-size: 12px !important; 
  }
  .navbar-buttons{
    justify-content: start !important;
  }
}

.user-display {
  display: flex;
  align-items: center;
  position: relative;
}

.avatar-user {
  width: 40px;
  height: 40px;
  background-color: #2F14E5;
  color: #ffffff;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; 
  cursor: pointer;
}

.username-role {
  display: flex;
  flex-direction: column;
}

.username-role h6 {
  margin: 0;
  color: #526484;
}
.username-role h6:first-child {
  color: #798BFF;
}
.notification-icon{
  cursor: pointer;
}
.notification-container {
  position: relative;
  margin-right: 10px; 
}

}

