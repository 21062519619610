.group-layout{
  border: 1px solid rgb(222, 218, 218);
  box-shadow: none !important;
  button{
    box-shadow: none !important;
    border: none !important;
  }
  .active{
    background-color: #e0ddef;
    border: none !important;
  }
}
.layoutFilter{
  width: 95%;
  margin-bottom: 20px;
}