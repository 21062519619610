.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0e006b;
  opacity: 0.6 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  opacity: 10;
  z-index: 50;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  text-align: center;

  button {
    margin: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: none !important;

    &.confirm {
      border-radius: 6px !important;
      background: #2F14E5 !important;
      color: #fff !important;
      text-align: center !important;
      font-size: 13px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      text-transform: uppercase !important;
      width: 100px !important;
      letter-spacing: 0.5px;
    }
    
    &.cancel {
      border-radius: 6px !important;
      color: #b3b3b3;
      background-color: #f0f0f0 !important;
      border: none !important;
      box-shadow: none !important;
      text-align: center !important;
      font-size: 13px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      text-transform: uppercase !important;
      width: 100px !important;
      letter-spacing: 0.5px;
    }
  }
}
