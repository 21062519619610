.tooltip-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  position: relative;
  .text{
    word-break: break-all;
  }
}

.tooltip {
  z-index: 99999;
  font-size: 12px;
  font-weight: 400;
  background-color: #111418;
  text-align: center;
  padding: 4px 8px;
  color: #fff;
  border-radius: 6px;
  border: 1px solid #111418;
  position: absolute;
  bottom: 100%; /* Place the tooltip above the container */
  left: 50%;
  transform: translate(-50%, -8px); /* Center horizontally, and offset vertically */
  opacity: 0 !important;
  transition: all 0.3s ease;
  word-break: break-word;
  height: 2.2em; /* Limit height to two lines of text */
  line-height: 1.25em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 450px; /* Limit the max width */
  min-width: 50px; /* Ensure it doesn’t shrink too much */
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #111418;
  position: absolute;
  bottom: 1px;
  left: calc(50% - 8px);
  opacity: 0 !important;
}

.arrow-up{
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #111418;
  position: absolute;
  top: 100%; /* Align the arrow to the bottom of the tooltip */
  left: calc(50% - 8px);
}

.arrow-down.open{
  opacity: 0.8 !important;
  transition: all 0.3s ease;
}

.tooltip.open {
  opacity: 0.8 !important;
  transform: translate(-50%, -7px); /* Adjust the vertical offset when visible */
  transition: all 0.3s ease;
  
}