.formPageInput {
    max-height: 70vh;
    overflow-y: auto;
    padding-bottom: 100px;
    .add-container {
        cursor: auto;
        margin-top: 10px;
        margin-left: 5px;
        width: 48%;
        border-radius: 8px;
        font-weight: 700;
        font-size: 12px;
        padding: 5px;
        margin-bottom: 8px;
        .add-param-tag{
            padding: 12px 16px 12px 16px;
            border-radius: 8px;
            color: #707274;
            font-weight: 600;
            font-size: 14px;
            border: 1px solid #707274;
            cursor: pointer;
            transition: all 0.1s;
        }
        .add-param-tag:hover{
            background-color: rgb(243, 241, 241);
            transition: all 0.1s;
        }
    }
    .nested-border{
        border: 1px solid #2F14E5 !important;
    }
    .template-input {
        width:48%;
        height: fit-content;
        border: 1px solid #ECECED;
        cursor: auto;
        box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
                0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
            border-radius: 20px;
            padding: 24px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
        .template-input-body{
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            list-style: none;
            color: #333;
            font-weight: 400;
            font-size: 1rem;
            font-family: sans-serif;
        }
        .template-input-actions {
            display: flex;
            gap: 10px;
            align-items: center;
            flex-direction: column;
            margin-top: 5vh;
            .grab-action{
                cursor: grab !important;
            }
            .template-input-action {
                display: flex;
                width: 18px;
                height: 18px;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                touch-action: none;
                cursor: var(--cursor, pointer);
                border-radius: 5px;
                border: none;
                outline: none;
                appearance: none;
                background-color: transparent;
                -webkit-tap-highlight-color: transparent;
                box-shadow: 1 0px 0px 2px #4c9ffead !important;
                border: 0.5px solid var(--primary-color-darker);
            }

            .template-input-action:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }

            .template-input-action:focus-visible {
                box-shadow: 0 0px 0px 2px #4c9ffe;
            }

            .template-input-action svg {
                flex: 0 0 auto;
                margin: auto;
                width: 10px;
                height: 10px;
                overflow: visible;
                fill: #919eab;
            }
        }


    }

    .edit-contract-clause-input-header-form {
        font-size: 20px;
        color: #556581;
        z-index: 1;
        opacity: 1 !important;
        background-color: white;
        padding-bottom: 0px;
        margin-bottom: 20px !important;
        width: 100%;
    }

    .edit-contract-clause-input {
        border: none;
    }

    .selected-input-container {
        border: 2px dashed var(--primary-color);
        transition: all 0.8s ease;
        opacity: 0.9;
        padding: 10px;
    }

    .variable-input {
        cursor: pointer;
    }

    .param-count-label {
        font-weight: 700;
        font-size: 12px;
        color: var(--primary-color-darker);
    }

    .input-group-title,
    .input-group-subtitle {
        font-weight: 700;
        color: var(--primary-color-darker);
    }

    .input-group-title {
        font-size: 17px !important;
    }

    .input-group-subtitle {
        font-size: 14px !important;
    }

    .benificial-button {
        font-weight: 600;
        background-color: var(--primary-color-medium);
        color: white;
        padding: 7px;
    }

    .grey-border {
        border: 2px solid #acabba !important;
        border-radius: 4px;
    }

    .green-border {
        border: 2px solid var(--ai-success-medium) !important;
        border-radius: 4px;
    }

    .radio-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        margin: auto;
        margin-top: 3%;
    }

    .switch input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .switch label {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #ccc;
        border-radius: 50%;
        transition: background-color 0.4s;
        cursor: pointer;
    }

    .switch input[type="checkbox"]:checked+label {
        background-color: var(--primary-color);
        border: 1px solid grey;
    }

    .switch label::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-color: white;
        border-radius: 50%;
        transition: background-color 0.4s;
    }

    .switch input[type="checkbox"]:checked+label::after {
        padding: 2px;
    }

    .ai-validate-button {
        font-weight: 600;
        font-size: 12px;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        transition: transform 0.1s ease-in-out;
    }

    .ai-validate-button:active {
        transform: scale(0.95);
    }

    .ai-response {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
    }



    .up-active {
        color: var(--ai-success-medium);
    }

    .down-active {
        color: var(--ai-error-medium);
    }

    .inactive {
        color: var(--primary-color);
    }

    .wrapper-form-input {
        display: flex;
        width: 100%;
        max-height: 62vh !important;
        overflow-y: auto;
        padding-bottom: 100px;
    }

    @media screen and (min-width: 1300px) and (max-width: 1500px) {
        .wrapper-form-input {
            max-height: 500px;
        }
    }

    .wrapper-form-input::-webkit-scrollbar {
        width: 8px;
    }

    .wrapper-form-input::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 4px;
    }

    .wrapper-form-input::-webkit-scrollbar-thumb {
        background-color: var(--neutral-light);
        border-radius: 4px;
    }

    .wrapper-form-input::-webkit-scrollbar-thumb:hover {
        background-color: var(--neutral-medium);
    }

    .form-item-input {
        flex: 1;
    }

    label {
        font-weight: 700;
        font-size: 12px;
        color: #2f14e5;
        font-style: normal;
    }

    .form-check-label {
        color: #484A4D;
        font-size: 12.9px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.64px;
        .boolean-check{
            margin-right: 8px;
        }
    }
}
.template-form{
    cursor: auto;
    .list-param-template{
        padding: 4px;
        justify-content: space-between;
    }
}