.app-container {
  min-height: 100vh;
}

.no-padding-right-row {
  padding-right: 0 !important;
}
/* update element label */
label {
  font-weight: 700;
  font-size: 12px;
  color: #2f14e5;
  font-style: normal;
}
label::first-word {
  text-transform: capitalize !important;
}
.main-content{
  flex-direction: row;
  flex-grow: 1;
  margin: 5px;
}
