.input-field {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  .input-label {
    color: #484A4D;
    font-weight: 450;
    font-size: 16px;
  }
  .input-element-container{
    position: relative;
    .arabic{
      left: 0 !important;
      right: auto !important;
    }
    .show-password {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 0;
      top: 15px;
      background-image: url('../../../../assets/new-design-icons/show_password.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
    .hide-password{
      width: 32px;
      height: 32px;
      position: absolute;
      right: 0;
      top: 15px;
      background-image: url('../../../../assets/new-design-icons/hide_password.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
    .input-element {
    width: 100%;
    height: 56px;
    border: 1px #E0E0E1 solid;
    border-radius: 8px;
    padding: 12px;
  }
  }
  

  .error {
    color: red;
    font-size: 14px;
  }
  .description{
    color: #484A4D;
    font-weight: 450;
    font-size: 14px;
    margin-top: 5px;
  }
}