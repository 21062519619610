.confirm-delete-popup {
  .question-modal-delete {
    color: #2b2e31 !important;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 50.6px;
    padding-top: 20px !important;
    padding-bottom: 30px;
    padding-left: 0 !important;
    margin-left: 0 !important;
    width: 100%;
  }
  .submit-delete,.make-inactive {
    border-radius: 6px !important;
    background: #2F14E5 !important;
    color: #fff !important;
    text-align: center !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    width: 300px !important;
    letter-spacing: 0.5px;
  }
  .cancel-delete {
    border-radius: 6px !important;
    color: #b3b3b3;
    background-color: #f0f0f0 !important;
    border: none !important;
    box-shadow: none !important;
    text-align: center !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    width: 300px !important;
    letter-spacing: 0.5px;
  }
}
