.contractz-lab-share {
  background-color: white;
  opacity: 1 !important;
  z-index: 1050 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  width: 700px;
  .share-buttons{
    display: flex;
    width: 100%;
  }
  .share-title {
    background: none;
    color: black;
    font-weight: bold;
    margin: 0 !important;
  }
  .sign-btn, .share-btn {
    background-color: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-align: center !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 300px !important;
    display: flex;
  }
  
  .share-title {
    background: none;
    color: black;
    font-weight: bold;
    margin: 0 !important;
  }
  
  .header-share {
    background-color: white;
    border-bottom: none !important;
  }
  .body-share {
    margin: 0 !important;
  }
  .share-contract-text {
    color: #8094AE;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-top: 20px;
    padding: 0;
  }
}
