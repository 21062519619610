.ressources-analytics {
    .pie-chart{
        min-width: 300px
    }
    .line-chart{
        min-width: 300px;
        max-width: 500px;
    }
  .charts-row{
    margin-top: 30px;
    gap: 30px;
  }
  .chart {
      border-radius: 6px;
      background: #FFF;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
      flex: 1;
      flex-shrink: 0;
      max-height: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 500px;
  }

  .charts-line {
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
  }

  .chart-line {
      border-radius: 6px;
      background: #FFF;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 240px;
  }

  h5 {
      font-weight: 700;
      color: var(--neutral-darker);
      font-size: 20px;
  }

  .charts {
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
      width: 100%;
  }

  .contract-row {
      border-bottom: 1px solid #DBDFEA;
      height: 54px;
      flex-shrink: 0;
      gap: 10px;
  }

  .contract-rows {
      max-height: 500px;
      overflow-y: auto;
      min-height: 150px;
  }

  .contract-header {
      border-bottom: 1px solid #DBDFEA;
      height: 44px;
      flex-shrink: 0;
      padding-right: 35px;
      gap: 10px;
  }

  .table-cell {
      align-items: center;
      justify-content: center;
      display: flex;
      overflow: hidden;
  }

  .header-cell {
      align-items: center;
      justify-content: center;
      display: flex;
  }

  .status-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      border-left: 1px solid #DBDFEA;
      padding-left: 20px;
      max-width: 120px;
  }

  .status-btn {
      background: #FFFFFF;
      border: 1px solid #E5E9F2;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 5px 10px;
      font-weight: 700;
      font-size: 10px;
      line-height: 21px;
      color: #2F14E5;
      cursor: pointer;
      width: 90px;
  }

  .borderB {
      border-bottom: 1px solid #DBDFEA;
      padding-bottom: 15px;
  }

  .active-status {
      background: #2F14E5 !important;
      color: #FFFFFF !important;
  }

  .dots {
      margin-top: 10px;
      padding-right: 5px;
      cursor: pointer;
  }

  .contracts-filters {
      display: flex;
      flex-direction: row;
      gap: 20px;
      max-height: 68px;
  }

  .contracts-filter {
      min-width: 220px;
      display: flex;
      flex-direction: row;
      gap: 10px;
  }

  .contracts-applied-filters {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
  }

  .applied-filter {
      background: #F5F6FA;
      border-radius: 4px;
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 700;
      color: #2F14E5;
  }

  .filter-remove {
      cursor: pointer;
  }

  .filter-remove:hover {
      color: #7014E5;
  }

  .filter-label {
      font-size: 12px;
      font-weight: 700;
      color: #2F14E5;
      margin-top: 30px;
  }
}