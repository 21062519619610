.form-page-edition{
  .progress{
    margin-bottom: 20px;
  }
  .form-title{
    display: flex;
    align-items: center;
    justify-content: center;
    .form-title-details {
      display: flex;
      align-items: left;
      flex-direction: column;

      .title {
        color: #2B2E31;
        font-weight: 600;
        font-size: 16px;
      }

      .modified {
        color: #8D8E90;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
.edit-form-input-list-container {
  height: 80vh;
  border-left: 0.5px solid rgb(222, 218, 218);
  border-radius: 10px 0px 0px 0px;
  padding: 1% !important;
  width: 100%;

  .selected-input-container {
    border: 2px dashed var(--primary-color);
    transition: all 0.8s ease;
    opacity: 0.9;
    padding: 10px;
  }

  .variable-input {
    cursor: pointer;
  }

  .param-count-label {
    font-weight: 700;
    font-size: 12px;
    color: var(--primary-color-darker);
  }

  .input-group-title,
  .input-group-subtitle {
    font-weight: 700;
    color: var(--primary-color-darker);
  }

  .input-group-title {
    font-size: 17px !important;
  }

  .input-group-subtitle {
    font-size: 14px !important;
  }

  .grey-border {
    border: 2px solid #acabba !important;
    border-radius: 4px;
  }

  .green-border {
    border: 2px solid var(--ai-success-medium) !important;
    border-radius: 4px;
  }

  .radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .switch input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .switch label {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ccc;
    border-radius: 50%;
    transition: background-color 0.4s;
    cursor: pointer;
  }

  .switch input[type="checkbox"]:checked+label {
    background-color: var(--primary-color);
    border: 1px solid grey;
  }

  .switch label::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    transition: background-color 0.4s;
  }

  .switch input[type="checkbox"]:checked+label::after {
    padding: 2px;
  }

  .ai-validate-button {
    font-weight: 600;
    font-size: 12px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    transition: transform 0.1s ease-in-out;
  }

  .ai-validate-button:active {
    transform: scale(0.95);
  }

  .ai-response {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .up-active {
    color: var(--ai-success-medium);
  }

  .down-active {
    color: var(--ai-error-medium);
  }

  .inactive {
    color: var(--primary-color);
  }
 .subtitle-form {
    color: #1f2b3a;
    font-size: 14.9px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 29.64px
  }

  .previous-btn-hidden,
  .next-btn-hidden {
    opacity: 0 !important;
    cursor: auto !important;
  }
  .form-page-footer {
        display: flex;
        width: 90%;
        justify-content: space-between;
        position: sticky;
        top: 100%;
        padding: 0px;
        left: 25%;
        height: 40px;
      }
    
      .previous-btn,
      .next-btn {
        background-color: #2F14E5;
        color: white;
        border-radius: 20px !important;
        width: 120px;
      }
}

/* save draft btn */
.save-draft-form-btn {
  padding: 8px 15px 10px 15px;
  border-radius: 800px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #2F14E5;
  color: #FFF;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.26px;
  position: absolute;
  right: 40%;
  width: 188.17px;
}

// steps css
.container-steps-form {
  .edit-form-steps {
    background-color: #f5f6fa;
    border-radius: 12px;
    font-size: 14px;
  }

  .edit-form-step {
    position: relative;
    font-size: 14px;
  }

  .edit-form-step-line {
    position: absolute;
    left: 12px;
    top: 40px;
    height: calc(100% - 24px);
    width: auto;
    transform: translateX(-50%);
  }
  .edit-form-step-line-arabic{
    right: 10px;
  transform: translateX(0%);
  }
}
.form-container {
  width:-webkit-fill-available;
}
}