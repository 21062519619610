.clause-segmentation{
  .param{
    font-weight: initial !important;
  }
  .param-value {
    font-weight: initial !important;
  }
}
.param {
  color: var(--primary-color-medium);
  font-weight: 900;
}
.param-value {
  color: var(--primary-color-darker);
  font-weight: 900;
}
.comment {
  color: var(--neutral-medium);
  font-weight: 600;
}
.selected-text {
  font-size: 16px;
}
.preserve-spaces{
  white-space: pre-wrap;
}
.highlighted-text {
  background-color: #fff4e7;
}
.selected-text:focus {
  background-color: white;
  border: none !important;
  outline: none;
  overflow-wrap: normal !important;
  cursor: text;
}
.comment-edit {
  cursor: text !important;
}
.comment-prefix {
  color: var(--primary-color-medium) !important;
  font-weight: 900 !important;
}

.segmented-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #000000;

  tbody {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  td {
    border: 1px solid #000000;
    padding: 8px;
    text-align: left;
    flex: 1;
  }

  tr {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: row;
  }
}