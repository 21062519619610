.use-case-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    width: 100%;

.ai-text-container {
    overflow-y: auto;
    background-color: var(--secondary-color-lighter);
    border: 2px dashed #888;
    transition: all 0.8s ease;
    opacity: 0.8;
    padding: 20px;
    max-height: 79vh;
    border-radius: 12px;
    white-space: pre-line;
}

.ai-text {
    font-weight: bold;
    font-size: 14px;
}



.ai-text-section {
    flex: 5;
    padding-top: 10px;
}

.ai-questions-section {
    flex: 6;
    margin-top: -2.5%;

}

.ai-questions-container {
    max-height: 79vh;
    height: 79vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
}
.ai-questions-section h4{
    font-weight: 900;
    font-size: 23px;
    color: var(--neutral-medium);
    padding-left: 5px;
}
.ai-questions-section h5 {
    font-weight: 700;
    font-size: 15px;
}

.section-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.regulation-container {
    stroke: 1px fill #EFF0F6;
    border-radius: 20px;
    width: 100%;
    border: 1.3px solid #EFF0F6;
    box-shadow: 0px 5px 14px rgb(8, 15, 52, 0.1);
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.regulation-container:hover {
    border: 1.5px solid var(--primary-color);
}

.regulation-questions {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 13px;
}
.question-container{
    display: flex;
}

.regulation-name {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50px;
    border-right: 1.3px solid #EFF0F6;
    font-weight: 600;
    font-size: 14px;
    flex: 1;
}

.opposite-rotation {
    writing-mode: vertical-lr; /* vertical right-to-left */
    letter-spacing: 0.4px;
}

.question-scoring {
    width: 150px;
    border-radius: 20px;
    border: 1.3px solid #EFF0F6;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.question-scoring-text {
    font-size: 14px;
    font-weight: bold;
    flex: 1;
}

.question-scoring-item {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
}

.question-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.question-text {
    color: #2F14E5;
    font-weight: 600;
    font-size: 16px;
}

.question-remidial {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.question-remidial-tag {
    background-color: #2F14E5;
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 15px;
    border-radius: 20px;
    height: 25px;
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.question-remidial-answer {
    font-weight: 600;
    font-size: 14px;
    flex: 1;
}
.question-scoring-list {
    display: flex;
    flex-direction: row;
    gap: 7px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}
.regulation-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 5px;
}
.completion-medium{
    background-color: var(--ai-warning-medium);
    padding: 2px 5px;
    border-radius: 5px;
    color: var(--ai-warning-darkest);
}
.completion-high{
    background-color: var(--ai-success-medium);
    padding: 2px 5px;
    border-radius: 5px;
    color: var(--ai-success-darkest);
}
.completion-low{
    background-color: var(--ai-error-medium);
    padding: 2px 5px;
    border-radius: 5px;
    color: var(--ai-error-darkest);
}
}