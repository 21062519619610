.stale {
    color: black;
    font-size: 16px;
    cursor: text;
    padding: 3px 4px;
    position: relative;

    .comment-prefix {
        color: var(--primary-color-medium) !important;
        font-weight: 900 !important;
    }

    .comment {
        color: var(--neutral-medium);
        font-weight: 600;
        background-color: rgb(198, 196, 196);
        border-radius: 6px;
    }

    .element-stale {}

    table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        tbody{
            display: flex;
            width: 100%;
            flex-direction: column;
        }
        td {
            border: 1px solid ;
            padding: 8px;
            text-align: left;
            flex: 1;
        }

        tr {
            display: flex;
            width: 100%;
            flex: 1;
            flex-direction: row;
        }
    }
}