.group-modal-body {
  .group-form {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 100%;
    .language-input{
      display: flex;
      align-items: center;
      gap: 30px;
      .language-input-name{
        flex: 1;
      }
      .language-select {
      font-size: 13px;
      padding: 5px;
      width: 90%;
      padding-left: 10px;
      overflow-wrap: break-word;
      border: 1px solid#d3d2da;
    }
    }
    

    .language-select:focus {
      border-color: gray;
    }

    .footer {
      padding-top: 10px;
      border-top: 1px solid #ccc;
    }
    .disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
    .languages{
      .language-header{
        display: flex;
        flex-direction: row;
        color: var(--primary-color);
        gap: 120px;
        div{
          width: 120px;
        }
      }
      .language{
        display: flex;
        flex-direction: row;
        gap: 120px;
        div{
          width: 120px;
        }
      }
    }
    .language-add-form{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      .language-input{
        height: 30px;
        padding: 10px;
      }
      .language-select{

      }
    }
  }
}