.contract-template-page {
  .contract-name {
    font-weight: 900;
    font-size: 17px !important;
    color: var(--neutral-dark);
    letter-spacing: -0.02em;
  }
  
  .contract-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .h4 {
    font-weight: 900;
    font-size: 23px;
    color: var(--neutral-medium);
  }
  
  .h5 {
    font-weight: 700;
    font-size: 15px;
  }
  
  .h6 {
    font-weight: 500;
    font-size: 14px;
    color: var(--neutral-medium);
  }
  
  .p {
    font-weight: 400;
    font-size: 14px;
    color: var(--neutral-medium);
  }
}





.param {
    color: var(--primary-color-medium);
    font-weight: 900;
  }
  
  .param-value {
    color: var(--primary-color-darker);
    font-weight: 900;
  }
  
  .comment {
    color: var(--neutral-medium);
    font-weight: 600;
  }
  
  .template-selected-text {
    font-size: 16px;
  }
  
  .highlighted-text {
    background-color: #fff4e7;
  }
  
  .template-selected-text {
    background-color: white;
    border: none !important;
    outline: none;
    overflow-wrap: normal !important;
    cursor: text;
  }
  
  .comment-edit {
    cursor: text !important;
  }
  
  .comment-prefix {
    color: var(--primary-color-medium) !important;
    font-weight: 900 !important;
  }
  
  .segment-text-line {
    background-color: white;
    border: none !important;
    outline: none;
    overflow-wrap: normal !important;
    cursor: text;
  }
  
  .option-label {
    border: none !important;
    outline: none;
    overflow-wrap: normal !important;
    cursor: text;
    font-size: 10px;
    position: absolute;
    bottom: -10px;
    color: #637491;
  }
  
  .insert-text {
    display: inline-block;
    transform: scaleX(-1);
    color: #637491;
    font-size: 10px;
  }