.beneficial-modal-component {
    .content-beneficial {
        min-height: 600px !important;
        height: 600px !important;
        width: 80% !important;
        margin: auto;
        overflow-y: hidden !important;
    }

    .modal-title {
        position: relative;
        color: #300962;
        font-style: normal;
        font-weight: 400;
    }
    .body-beneficial {
        overflow-y: hidden;
        overflow-x: hidden;
        height: fit-content !important;
        padding-bottom: 45%;
    }

    .slider-wrapper {
        display: flex;
        width: 100% !important;
        text-align: center;
        align-items: center;
        margin-top: 30px !important;
        align-self: center;
        margin: auto;
        border-radius: 10px !important;
    }

    .slider-option {
        flex: 1;
        text-align: center !important;
        cursor: pointer;
        background-color: #E5E9F2;
        transition: background-color 0.3s ease;
        padding: 10px;
        height: 40px;
        color: #B6C6E3;
    }

    .slider-option:hover {
        background-color: #e0e0e0;
    }

    .selected-type-beneficial {
        background-color: #2f14e5 !important;
        color: #ffffff;
    }

    .right {
        border-radius: 0px 10px 10px 0px;
    }

    .left {
        border-radius: 10px 0px 0px 10px;
    }

    .label-beneficial {
        color: #344357 !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 23.1px;
    }

    .container-input-beneficial {
        width: 350px;
    }

    .container-input-beneficial>input {
        width: 100% !important;
    }

    .add-beneficial-btn {
        border-radius: 800px !important;
        border: 1px solid #2f14e5 !important;
        background: #2f14e5 !important;
        width:fit-content !important;
        min-width: 200px !important;
        height: 43px !important;
        box-shadow: none !important;
        display: flex;
        margin-left: auto;
        text-align: center !important;
    }

    .content-input-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-height: 380px !important;
        overflow-y: auto;
        overflow-x: hidden !important;
        height:fit-content !important;
        padding-bottom: 0px !important;
        // background-color: red;
    }

    .profile-picture-container {
        position: relative;
        width: fit-content;
        height: fit-content;
        cursor: pointer;
        margin-right: 64%;
    }

    .profile-picture-container img {
        display: block;
        margin-right: auto;
    }

    .profile-picture-container input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    .beneficial-profile-img {
        display: block;
        max-width: 150px;
        max-height: 80px;
    }

    .input-companyName-container {
        position: relative;
    }

    .company-name-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 2000;
        border-radius: 12px;
        border: 1px solid #E5E9F2;
        background: #FFF;
        box-shadow: 0px 3px 12px 1px rgba(23, 12, 47, 0.15);
        width: 100%;
        font-style: normal;
        font-weight: 500;
        line-height: 22.8px;
        padding-left: 4%;
        padding-top: 2%;
        overflow-y: auto;
        max-height: 100px;

        div{
            margin-top: 5px;
        }
    }

    .add-new-beneficial {
        border-radius: 50% !important;
        width: 30px !important;
        margin-top: 15px;
        height: 30px !important;
        color: #fff;
        font-size: 15px;
        padding: 0;
        background-color: #2f14e5 !important
    }

    .lookup-img {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }

    .input-beneficial-container>input,
    .input-companyName-container>input {
        width: 100% !important;
    }
    .lookup-img-arabic{
        left: 15px;
        right: auto;
      }

     .select-options-beneficial{
        width: 100% !important;
        padding: 10px !important;
        color: #212529 !important;
        font-weight: 600 !important;
     } 
}