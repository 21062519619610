.container-ressources-project{
  display: flex;
  border: 1px solid  #ACABBA;
  padding: 2%;
  margin-top: 2%;
  overflow-x: scroll;
  width: 100%
}
  .resource-table {
    .name{
      margin-left: 20px;
    }
    .actions {
      position: relative;
      .ai-icon{
        width: 30px;
        height: 30px;
      }
      .popup {
        position: absolute;
        width: 100px;
        background-color: white;
        z-index: 50;
        
        .contract-card-actions-modal {
          border: 1px solid#1c1d2212;
          border-radius: 4px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          position: absolute;
          top: 80%;
          right: 40px;
          padding: 8px;
          background-color: white;
          width: fit-content;
          left: -30px;
        }
      
        .contract-card-actions-modal ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
      
        .contract-card-actions-modal li {
          cursor: pointer;
          padding: 5px;
          width: 100%;
          transition: background-color 0.3s ease;
        }
      
        .contract-card-actions-modal li:hover {
          background-color: #f2f2f2;
        }
  
        li:hover {
          background-color: #F5F5F5;
          cursor: pointer;
        }
      }
  
      .plus-icon {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: gray;
        border: 1px solid gray;
        box-shadow: none;
        background-color: white;
      }
  
      .plus-icon:hover {
        background-color: #F5F5F5;
      }
    }
  
    .plus-icon:hover {
      background-color: #F5F5F5;
    }
    .header{
      display: flex;
    }
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
  
      tr {
        border-bottom: 2px solid #b5b7b7;
      }
  
      th {
        padding: 12px;
        text-align: left;
        font-weight: bold;
        background-color: inherit;
      }
      th,
      td {
        word-wrap: break-word;
        white-space: normal;
        padding: 12px;
        border: 1px solid #ddd; 
        text-align: left;
      }
      td {
        overflow-wrap: break-word;
            white-space: normal;
      }
      .title-table-item div {
        overflow-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        max-width: 100px; 
      }
    }
  
    tbody {
      display: block;
      max-height: 400px;
      overflow-y: auto; 
  
      tr {
        display: table;
        width: 100%;
        table-layout: fixed; 
      }
  
      td {
        padding: 12px;
        text-align: left;
      }
    }
  }
.projectTemplateRessources{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  .admin-ressource-card-container{
    margin-top: 1%;
  }
  .groups-ressource-project{
    position: absolute;
    top: 80px;
    z-index: 5;
  }
}
.document-name {
  font-weight: 900;
  font-size: 18px;
  color: var(--neutral-dark);
  letter-spacing: -0.02em;
  margin-top: 15px;
}
.container-ressources-project .admin-ressource-card-container{
 margin-left: 17px;
 min-width: 250px;
 width: fit-content;
}
.document-required{
  font-weight: 700;
  font-size: 13px;
  color: var(--neutral-dark);
  max-width: 832px;
}
.document-id {
  font-weight: 500;
  font-size: 17px;
  color: var(--neutral-light);
}

.document-status {
  font-weight: 700;
  font-size: 16px;
  color: var(--neutral-darker);
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: flex-start;
}

.document-date,
.document-creator {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: flex-start;
}

.document-date>span,
.document-creator>span,
.document-status>span {
  width: 46%;
}

.document-type {
  color: #d3d2da;
}

.actions-document {
  position: absolute;
  bottom: 15px;
  width: 90%;
  display: flex;
}
.rounded-circle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  border: 2px solid #1c1d2212;
  background-color: white;
}