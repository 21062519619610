.clause-comparison-modal {
    * {
        opacity: 1 !important;
    }

    .clause-comparison-modal-content {
        width: 90% !important;
        background-color: white !important;
        border-radius: 10px !important;
        cursor: auto;
        height: 80% !important;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .body-comparison {
            padding-top: 20px !important;
            padding: 30px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            flex: 1;
            display: flex;
            font-size: 15px;
        }

        .clause-comparison-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;
            flex: 1;
            overflow: hidden;
        }

        .clause-comparison-current {
            border: 2px dashed #D9D9D9;
            border-radius: 10px;
            padding: 13px;
            flex: 1;
            display: flex;
            flex-direction: column;

        }

        .clause-comparison-chosen {
            border: 2px dashed #D9D9D9;
            border-radius: 10px;
            padding: 13px;
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            max-height: 50vh;
        }

        .clause-comparison-levels {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-bottom: 50px;
            width: 50%;
            padding-left: 3%;
        }

        .clause-comparison-cummon-title {
            color: var(--secondary-color-darker, #300962);
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            letter-spacing: -0.24px;
        }

        .clause-comparison-actual-title {
            color: var(--secondary-color-darker, #300962);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            letter-spacing: -0.4px;
            position: relative;
            display: inline;
            padding-bottom: 8px;
        }

        .clause-comparison-actual-title::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 40px;
            border-bottom: 4px solid #7014E5;
        }

        .clause-comparison-body {
            flex: 1;
            overflow-y: auto;
            font-size: 15px;
        }

        .clause-comparison-body-select {
            overflow-y: auto;
            flex: 1;
        }

        .clause-comparison-header {
            height: 65px;
        }

        .clause-comparison-select-container {
            max-width: 100%;
            height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 18px;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            padding: 0 10px;
            margin-bottom: 20px;
        }

        .clause-comparison-select-container::-webkit-scrollbar {
            height: 8px;
        }

        .clause-comparison-select-element {
            flex: 0 0 auto;
            height: 40px;
            padding: 5px 10px 8px 10px;
            justify-content: center;
            align-items: center;
            border-radius: 800px;
            border: 1px solid #E5E9F2;
            background: #E5E9F2;
            cursor: pointer;
            color: #000;
        }

        .clause-comparison-select-element-text {

            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.26px;
            margin: auto;
        }

        .selected-comparison {
            color: white !important;
            background-color: #2F14E5 !important;
        }

        .confirm-comparison-buttons {

            justify-content: center;
            align-items: center;
            display: flex;
            position: absolute;
            flex-direction: row;
            bottom: 5px;
            right: 0;
            gap: 10px;
        }

        .confirm-comparison-button {
            border-radius: 5px;
            border: 1px solid #2F14E5;
            background: #2F14E5;
            color: #FFF;
            text-align: center;
            float: right;
            margin-top: 50px;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.26px;
            width: 171px;
            height: 36px;
            padding: 9px 10px;
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }
}