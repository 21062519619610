.type-add-popup {
  .modal-content {
    min-width: 600px;
  }
  .body-add-type {
    min-width: 600px;
    max-height: 78vh;
  }
  .error-message {
    color: red;
    margin-top: 1%;
    margin-bottom: -5%;
    font-size: 13px;
  }
  .form-group {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    label{
      color: #344357;
      font-size: 17px;
      font-style: normal;
      font-weight:normal;
      line-height: 23.1px;
    }
    .form-control {
      width: 100% !important;
    }
  }
  .actions-popup-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .type-role-select {
    width: 100%;
    margin-top: 6%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }

  .type-role-select:focus {
    outline: 0;
    box-shadow: none;
  }

  .type-role-select option {
    padding: 5rem !important;
    font-size: 1rem;
    color: black;
    cursor: pointer !important;
  }


  .actions-popup-type {

    .add-signle-type,
    .cancel-adding-type {
      padding: 8px;
      width: 110px;
      box-shadow: none;
    }

    .cancel-adding-type {
      color: #b3b3b3;
      background-color: #f0f0f0;
    }
  }
  .remove-type-level{
    margin-left: 12px;
    margin-top: 15px;
    font-size: 20px;
    width: 18px;
    cursor: pointer;
  }
  .btn-handler-type {
    border-radius: 50% !important;
    width: 20px !important;
    height: 20px !important;
    margin-top: 12px;
    color: #fff;
    padding: 0;
    background-color: #2f14e5 !important;
    box-shadow: none !important;
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: space-around;
 }
 /* Styles for the autocomplete list */
.autocomplete-list {
  list-style-type: none;
  margin: 0;
  margin-top: 85px;
  padding: 0;
  position: absolute;
  max-height: 80px;
  overflow-y: auto;
  width: 100%;
  border: 1px solid #ddd;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.autocomplete-list li {
  padding: 8px;
  font-size: 15px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.autocomplete-list li:last-child {
  border-bottom: none;
}

.autocomplete-list li:hover,
.autocomplete-list li.active {
  background-color: #f0f0f0;
}

}
