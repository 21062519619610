.delete-body{
  margin-top: 10px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .delete-body-title{
    font-size: 20px;
    font-weight: 700;
    color: var(--neutral-medium);
    margin-bottom: 10px;
  }
  .delete-body-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    .delete-action{
      min-width: 5vw;
      height: 40px;
      background-color: var(--secondary-color-lighter);
      color: var(--neutral-medium);
    }
    .active{
      background-color: var(--primary-color);
      color: white;
    }
  }
}