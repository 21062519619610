.full-screen{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-wrapper{
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .modal-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      .modal-title{
        font-size: 20px;
        font-weight: 700;
      }
    }
    .modal-body{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .modal-input{
        width: 80%;
        margin-bottom: 10px;
      }
      .error{
        color: red;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .disabled-confirm-button{
        background: #E5E9F2;
        color: #000;
        border: 1px solid #E5E9F2;
        cursor: not-allowed;
      }
      .modal-body-text{
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      .modal-body-buttons{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .modal-body-button{
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
          font-weight: 700;
          &.cancel{
            background-color: #F5F6F8;
          }
          &.confirm{
            background-color: #7014E5;
            color: white;
          }
        }
      }
    }
  }
}