.contract-container {
    .clause-list-container {}

    .selected {
        background-color: var(--secondary-color-lighter);
        border: 2px dashed #888;
        transition: all 0.8s ease;
        opacity: 0.9;
        padding: 10px;
        max-height: 79vh;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 20px;

    }

    .variable-input-container {
        padding: 10px;
        max-height: 79vh;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 20px;
    }

    ::-webkit-scrollbar {
        width: 6px !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888 !important;
        border-radius: 6px !important;
    }

    ::-webkit-scrollbar-track {
        background-color: #f0f0f0 !important;
    }

    /* Firefox */
    ::-moz-scrollbar {
        width: 6px !important;
    }

    ::-moz-scrollbar-track {
        background: #f0f0f0 !important;
    }

    ::-moz-scrollbar-thumb {
        background: #888 !important;
        border-radius: 6px !important;
    }
    .ai-text{
        font-weight: bold;
        font-size: 14px;
        white-space: pre-line;
    }
    .completion-low {
        background-color: var(--ai-error-medium);
    padding: 2px 5px;
    border-radius: 5px;
    color: var(--ai-error-darkest);
     }
     .completion-medium {
        background-color: var(--ai-warning-medium);
        padding: 2px 5px;
        border-radius: 5px;
        color: var(--ai-warning-darkest);
    }
    .completion-high {
        background-color: var(--ai-success-medium);
        padding: 2px 5px;
        border-radius: 5px;
        color: var(--ai-success-darkest);
    }
}