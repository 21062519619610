.table-options-filter-container {
  width: 100%;
  position: relative;
  cursor: pointer;
  .current-choice {
    width: 100%;
    position: relative;
    padding-left: 30px;
    margin: 0;
    height: 40px;
    z-index: 2;
    width: 100%;
    background-color: white;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    font-weight: 400;
    font-size: 15px;
    color: #7a7a7f;
    display: flex;
    align-items: center;
    span{
      width: 100%;
    }
  }

  .options-dropdown{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid var(--neutral-light);
    border-radius: 4px;
    z-index: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    overflow-y: auto;
    max-height: 200px;
    .option-dropdown{
      width: 100%;
      padding: 3px;
    }
    .option-dropdown:hover{
      background-color: var(--neutral-light);
    }
  }
}