.regenerate-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px;
  flex: 1;
  max-height: 117px;
  z-index: 1;
  border: 2px dashed #D9D9D9;
  border-radius: 10px;
  overflow-y: auto;
  .content-section{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    margin: 10px;
  }
  .prompt-section{
    z-index: 99;
  }
  .body-interactions-input-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    min-height: 50px;
    max-height: 230px;
    border-radius: 35px;
    padding: 10px;
    background-color: rgb(239, 238, 243);
    .add-file{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
    }
    .input-section{
      flex: 1;
      display: flex;
      flex-direction:column;
      gap: 5px;
      justify-content: left;
      align-items: left;
      .added-file{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        margin-left: 20px;
      }
      .query-input{
        // disble resize
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 0;
        margin-top: 15px;
        padding-bottom: 0;
        font-size: 17px;
        font-weight: 500;
        min-height: 48px;
        max-height: 100px;
        width: 100%;
      }
      .query-input:focus{
        outline: none;
      }
    }
    .button-section{
      
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;
      .ask-btn{
        background-color: transparent;
        border: none;
        box-shadow: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        .icon{
          color: white;
          width: 40px;
          height: 40px;
        }
      }
    }
    .ask-input{
      border-radius: 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      flex: 9;
    }
    .ask-button{
      flex: 1;
      border-radius: 20px;
      color: white;
    }
  }
  .section-title{
    font-size: 20px;
    font-weight: 600;
    color: var(--neutral-darker);
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
  }
  .instructions-input{
    width: 100%;
    height: 150px;
    border-radius: 8px;
    border: 2px solid #2F14E5;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: var(--neutral-darker);
    min-height: 50px;
  }
  .params-container{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 50vw;
    overflow-x: auto;
  }
  .add-param-form-container{
    width: 200px;
  }
  .add-param{
    width: 150px;
    height: 100px;
    border-radius: 8px;
    font-weight: 600;
    color: var(--neutral-darker);
    border: 2px solid #2F14E5;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d6d1f4;
  }
  .regulation-list{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 50vw;
  }
  .regulation{
    padding-top: 10px;
    width: 150px;
    height: 100px;
    border-radius: 8px;
    border: 2px solid #2F14E5;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .selected-regulation{
    background-color: #d6d1f4 !important;
  }
  .param-display{
    padding: 10px;
    position: relative;
    padding-top: 10px;
    min-width: 150px;
    max-width: 350px;
    min-height: 100px;
    max-height: 150px;
    border-radius: 8px;
    border: 2px solid #2F14E5;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    .remove-param{
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 10px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid #b20303;
      width: 15px;
      height: 15px;
      color: #b20303;
      cursor: pointer;
    }
    .param-info{
      font-size: 16px;
      font-weight: 600;
      color: var(--neutral-darker);
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}