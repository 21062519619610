.selected-input-container {
    border: 2px dashed var(--primary-color);
    transition: all 0.8s ease;
    opacity: 0.9;
    padding: 10px;
}
.variable-input {
    cursor: pointer;
}

.param-count-label {
    font-weight: 700;
    font-size: 12px;
    color: var(--primary-color-darker);
}

.input-group-title,
.input-group-subtitle {
    font-weight: 700;
    color: var(--primary-color-darker);
}

.input-group-title {
    font-size: 17px !important;
}

.input-group-subtitle {
    font-size: 14px !important;
}
.benificial-button {
    font-weight: 600;
    background-color: var(--primary-color-medium);
    color: white;
}
.grey-border {
    border: 2px solid #acabba !important;
    border-radius: 4px;
}
.green-border {
    border: 2px solid var(--ai-success-medium) !important;
    border-radius: 4px;
}
.radio-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: auto;
    margin-top: 3%;
    label{
        color:#484A4D !important;
    }
    .boolean-check{
        margin-right: 8px;
    }
}

.switch input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.switch label {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ccc;
    border-radius: 50%;
    transition: background-color 0.4s;
    cursor: pointer;
}

.switch input[type="checkbox"]:checked + label {
    background-color: var(--primary-color);
    border: 1px solid grey;
}

.switch label::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    transition: background-color 0.4s;
}

.switch input[type="checkbox"]:checked + label::after {
    padding: 2px;
}

.ai-validate-button {
    font-weight: 600;
    font-size: 12px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    transition: transform 0.1s ease-in-out;
}

.ai-validate-button:active {
    transform: scale(0.95);
}

.ai-response {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
}

.cursor-pointer {
    cursor: pointer !important;
}

.up-active {
    color: var(--ai-success-medium);
}

.down-active {
    color: var(--ai-error-medium);
}
.inactive {
    color: var(--primary-color);
}
