
.input-param{
  flex: 1;
  display: flex;
  gap: 10px;
  flex-direction: column;
  height: 100%;
  .input-type {
  border-radius:4px;
  background:#FFF9EA;
  padding: 2px;
  font-weight: 600;
  font-size: 12px;
  height: 20px;
  gap:10px;
  text-align: center;
  color: #523700;
  margin-left: auto;
  margin-top: -1%;
}
.formula {
  position: relative;
}

.dropdown-list-formula {
  position: absolute; 
  background-color: #fff; 
  border: 1px solid #ccc;
  border-radius: 4px; 
  max-height: 200px;
  overflow-y: auto; 
  z-index: 1000; 
  width: 100%; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0; 

  li {
    padding: 12px 16px; 
    cursor: pointer;
    transition: background-color 0.2s ease; 

    &:hover {
      background-color: #f0f8ffb5; 
      color: #2F14E5; 
    }

    &:active {
      background-color: #e0e0e0;
    }
  }
}

.dropdown-list-formula {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0); 
  }
}
.error {
  color: red;
  margin-top: 10px;
}

.custom-form-label{
  outline: none; 
  display: flex;
  align-items: center;
  justify-content: left;
  width: 95%;
  overflow-wrap: break-word;
  line-break: anywhere;
  font-weight: 700;
  font-size: 14px;
  color:#2B2E31;
  text-align: justify;
}
.custom-form-textarea{
  border: 1px solid #E0E0E1;
  border-radius: 8px;
  padding: 12px, 12px, 104px, 12px;
  outline: none; 
  display: flex;
  align-items: center;
  width: 95%;
  overflow-wrap: break-word;
  font-weight: 700;
  font-size: 12px;
  max-height: 80px;
  padding-left: 12px;
}
}
.input-progress-done {
  padding: 2px 5px;
  min-width: 70px;
  font-weight: 700;
  font-size: 12px;
  width: 88.47px;
  height: 25px;
  color: #0fca7a;
  text-align: center;
  letter-spacing: 0.1px;
  border-radius: 800px;
  border: 1px solid #e5f9f0;
  background: #E5F9F0;
}

.input-error {
    background-color: var(--ai-error-clear);
    color: var(--ai-success-darkest);
    font-weight: 700;
    font-size: 12px;
    height: 25px;
    min-width: 70px;
    text-align: center;
}
/* update boolean type into checkbox */
.segmented-control {
    display: flex;
  }
  .segment {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #e5e9f2;
    flex: 1;
    min-width: 40px;
    position: relative;
    background-color: #e5e9f2;
  }
  
  .segment.active {
    background-color: #7014e5;
  }
  .segment.segment.active::after {
    background: none !important;
  }
  
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important;
}
.custom-form-textarea{
  border: 1px solid #101f45;
  outline: none; 
  display: flex;
  align-items: center;
  padding: auto; 
  width: 100%;
  height: 58px;
  padding-top: 2vh!important;
  padding-right: 2%;
  overflow-wrap: break-word;
  font-weight: 700;
  font-size: 12px;
  width: 40%;
}
.wrapper-item-list-form{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 0% !important;
  margin: 0% !important;
}
.wrapper-item-list-form >div {
  width: 49.6%;
  margin-bottom:-10px;
}
.form-template-list-params{
  padding: 25px;
  width: 100% !important;
  .add-single-option{
    border-radius: 50% !important;
    width: 20px !important;
    height: 20px !important;
    color: #fff;
    padding: 0;
    background-color: #2f14e5 !important;
    box-shadow: none !important;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
.input-param{
  .ms-2 {
    margin-left: auto !important;
    cursor: pointer;
  }
  .param-current-name{
    color: #523700;
  }
  .param-current{
    border-radius: 4px;
    background: #FFF9EA;
    padding: 2px;
    font-weight: 600;
    font-size: 12px;
    height: 20px;
    gap: 10px;
    text-align: center;
    color: #523700 !important;
    margin-top: -1%;
  }
  .param-type-list-item{
    color: #523700 !important;
    background: #FFF9EA;
    cursor: pointer;

  }
  .add-single-option{
    border-radius: 50% !important;
    width: 23px !important;
    height: 23px !important;
    color: #fff;
    padding: 0;
    background-color: #2f14e5 !important;
    box-shadow: none !important;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .add-single-option:hover{
    font-weight: 700;
    font-size: 15px;
    transition: 0.2s;
  }
  .checkbox-beneficial{
    label{
      color: #333333 !important;
    }
  }
  .header-table-edit{
    font-weight: bold;
    color: #333333;
  }
}