.table-grid-selector {
  display: inline-block;
  margin: 10px;
  text-align: left;

.grid-container {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}

.grid-row {
  display: flex;
}

.grid-cell {
  width: 15px;
  height: 15px;
  border: 1px solid #ddd;
  margin: 1px;
  background-color: #f1f1f1;
  transition: background-color 0.2s;

}

.grid-cell.selected-cell {
  background-color: #007bff;
}

.grid-info {
  margin-top: 10px;
  font-size: 14px;
}
}