.ressources h1 {
    font-weight: 700;
    color: var(--neutral-darker);
    font-size: 16px;
}

.contracts-table{
    margin-top: 20px;
    width: 95%;
}

.search-icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

input[type="text"] {
    padding-left: 30px;
    text-decoration: none; /* Adjust the left padding to accommodate the search icon */
}
.search-input {
    height: 48px;
    width: 408px;
    border-radius: 8px;
    border: 1px solid var(--neutral-light);
}
.search-zone {
    width: 100%;
    display: flex;
    margin-top: 20px;

.search-section{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .search {
        position: relative;
        display: flex;
        border-radius: 8px !important;
        .search-input{
            border-radius: 8px;
        }
    }
}
.ressources-filters {
    flex: 1;
    display: flex;
    gap: 10px;
    justify-content: start;

    .filter-item {
        flex: 0 0 170px; 
        display: flex;
        align-items: center;

        .custom-combobox-main {
            padding: 5px;
            background-color: white;

            .custom-combobox-container {
                margin: auto !important;
                border: 1px solid var(--neutral-light);
                border-radius: 8px !important;
                padding: 3px;
            }

            .custom-combobox-selected {
                color: #707274 !important;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    .filter-type-item {
        display: flex;
        align-items: center;
    }
}

}
.filter > div:first-child {
    font-weight: 700;
    font-size: 12px;
    color: var(--neutral-medium);
}

.filter > div:nth-child(2) {
    color: var(--primary-color-darker);
    font-weight: 400;
    font-size: 14px;
}

.ressource-space {
    border: 2px dashed var(--neutral-light);
    min-height: 55vh !important;
    border-radius: 15px;
    margin-bottom: 10px;
    width: 30%;
}

.ressource-space-title {
    font-family: "Hanken Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 20.7436px;
    color: var(--neutral-medium);
}

.ressources-per-level{
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.ressources-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
}
.ressource-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

