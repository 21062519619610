.fade-enter {
  opacity: 0;
  transform: scale(0.1);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 400ms, transform 400ms;
}
.fade-exit {
  opacity: 0;
  transform: scale(1);
  display: none !important;
  background-color: red;
}
.fade-exit-active {
  display: none !important;
}


.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}
.slide-exit {
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}