.selected-clause {
  background-color: var(--secondary-color-lighter) !important;
  border: 2px dashed #888 !important;
  transition: all 0.8s ease;
  opacity: 0.9;
  max-height: 79vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.edit-template-contract-clause {
  border-radius: 12px;
  box-shadow: 1px 1px 1px 1px #e5e9f2, -1px -1px 1px 1px #e5e9f2;
  .preserve-spaces{
    white-space: pre-wrap;
  }
  .edit-groups-container{
    position: absolute;
    left: 0;
    top: 35px;
    border-radius: 0 12px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .generate-ai-container{
    position: absolute;
    left: 20px;
    top: 35px;
    border-radius: 0 12px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .edit-contract-template-clause-header-icons{
    width: fit-content;
    position: relative;
  }
  .subClause-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 50px;
}


  /* Webkit (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }

  /* Firefox */
  ::-moz-scrollbar {
    width: 6px;
  }

  ::-moz-scrollbar-track {
    background: #f0f0f0;
  }

  ::-moz-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

  .selectable-clause {
    cursor: pointer !important;
  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
  }

  .scrollable-clause {
    max-height: 80vh;
    overflow-y: auto;
  }


  .edit-contract-clause-header {
    font-size: 24px;
    color: #556581;
    display: flex;
    justify-content: space-between;
  }

  .edit-contract-clause-comment {
    font-size: 22px;
    color: #ffffff;
    margin-bottom: 10px;
    padding: 5px;
    max-height: 150px;
  }

  .speech-bubble {
    position: relative;
    max-width: 20em;
    background-color: #7014e5;
    padding: 1.125em 1.5em;
    font-size: 12px;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
  }

  .input-comment {
    background-color: #7014e5;
    padding: 3px;
    margin: 0;
    font-size: 12px;
    border: none;
    color: #ffffff;
  }

  .input-comment:focus {
    outline: none;
  }

  .scrollable-clause::-webkit-scrollbar {
    width: 8px;
  }

  .scrollable-clause::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 4px;
  }

  .scrollable-clause::-webkit-scrollbar-thumb {
    background-color: var(--neutral-light);
    border-radius: 4px;
  }

  .scrollable-clause::-webkit-scrollbar-thumb:hover {
    background-color: var(--neutral-medium);
  }

  .edit-contract-clause-header-icons {
    min-width: 90px;
    margin-left: 93%;
    height: 40px;
  }

  .triangle {
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #7014e5;
    /* You can set the color to your preference */
    position: absolute;
    top: -12px;
    left: 17px;
  }

  .comment-text {
    max-height: 70px;
    overflow-y: auto;
    word-wrap: break-word;
  }
}