.edit-project{
  width: 100%;
  .page-header{
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    .left-section {
      margin-left: 30px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;

      .close {
        border-right: 1px solid #E5E5E5;
        padding-right: 15px;
      }

      .title {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .name {
          color: #2B2E31;
          font-weight: 600;
          display: flex;
          align-items: center;
          font-size: 18px;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .page-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    .section{
      margin-top: 50px;
      width: 55vw;
      display: flex;
      flex-direction: column;
      .disabled-update{
        background-color: #F5F6F6 !important;
        color: #E0E0E1 !important;
        cursor: not-allowed;
      }
      .disabled-create{
        background-color: #F5F6F6 !important;
        color: #E0E0E1 !important;
        cursor: not-allowed;
      }
      .update-project-button{
        padding: 12px, 16px, 12px, 16px;
        background-color: #23C286;
        color: white;
        border-radius: 8px;
        width: 190px;
        height: 80px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
      }
      .create-project-button{
        width: 180px;
        height: 50px;
        margin-bottom: 20px;
        cursor: pointer;
        padding: 8px 15px 10px 15px;
       border-radius: 800px !important;
       justify-content: center;
    align-items: center;
    display: flex;
    background: #2F14E5;
    color: #FFF;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.26px;
      }
      .section-title{
        color: #2B2E31;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .section-body{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .template-reqs{
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: wrap;
          .ressource-disabled{
            border: 1px solid #ECECED !important;
            background-color: #FAFAFA !important;
            .title{
              color: #707274 !important;
            }
          }
          .ressource-card {
            cursor: pointer;
            width: 220px;
            height: 170px;
            background-color: #EAE8FC;
            border: 1px solid #2F14E5;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 10px;
            overflow: hidden;
            .title {
              color: #2F14E5;
              font-weight: 600;
              text-align: center;
              max-width: 200px;
              max-height: 50px;
              display: -webkit-box;            
              -webkit-box-orient: vertical; 
              -webkit-line-clamp: 2;          
              line-clamp: 2;                  
              word-break: break-word;          
              white-space: normal;    
            }
          }
        }
        .description-input{
          textarea{
            height: 140px;
            min-height: 40px;
            max-height: 200px;
          }
        }
        .lookup-img-arabic{
          left: 15px !important;
          right: auto !important;
        }
        .selected-beneficials{
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: wrap;
          .selected-beneficial{
            border: 1px #ECECED solid;
            border-radius: 100px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            gap: 5px;
          }
        }
      }
    }
  }
}