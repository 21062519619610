.edit-form-template-sidebar{
  width: 100% !important;
  background-color: #f5f6fa;
  border-radius: 12px;
  font-size: 14px;
  padding: 20px;
  min-width: 300px;
  .groups-form-template{
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 2;
  }
  .page-title{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    position: relative;
    
    .page-title-input{
      flex: 1;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid #D9D9D9;
      width: 100%;
      height: 30px;
      word-break: break-all;
      word-wrap: break-word;
    }
    .page-title-label{
      flex: 1;
    }
  }
  .edit-form-step{
    position: relative;
  }
  .edit-form-step-line {
    position: absolute;
    left: 12px;
    top: 40px;
    height: calc(100% - 24px);
    width: auto;
    transform: translateX(-50%);
  }
  .edit-form-step-line-arabic{
    right: 10px;
  transform: translateX(0%);
  }
}