.input-inprogres {
  padding: 2px;
  min-width: 70px;
  font-weight: 700;
  font-size: 12px;
  height: 25px;
  color: #523700;
  text-align: center;
  letter-spacing: 0.1px;
  background: #FFF9EA;
  border-radius: 4px;
}

.input-progress-done {
  padding: 2px;
  min-width: 70px;
  font-weight: 700;
  font-size: 12px;
  height: 25px;
  color: #0C4530;
  text-align: center;
  letter-spacing: 0.1px;
  background: #EBF4EC;
  border-radius: 4px;
}

.input-error {
    background-color: var(--ai-error-clear);
    color: var(--ai-success-darkest);
    font-weight: 700;
    font-size: 12px;
    height: 25px;
    min-width: 70px;
    text-align: center;
}
/* update boolean type into checkbox */
.segmented-control {
    display: flex;
  }
  .segment {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #e5e9f2;
    flex: 1;
    min-width: 40px;
    position: relative;
    background-color: #e5e9f2;
  }
  
  .segment.active {
    background-color: #7014e5;
  }
  .segment.segment.active::after {
    background: none !important;
  }
  
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important;
}
.custom-form-textarea{
  border: none;
  outline: none; 
  display: flex;
  align-items: center;
  padding: auto; 
  width: 100%;
  height: 58px;
  padding-top: 2vh!important;
  padding-right: 2%;
  overflow-wrap: break-word;
  font-weight: 700;
  font-size: 12px;
}
.wrapper-item-list-form{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 0% !important;
  margin: 0% !important;
}
.wrapper-item-list-form >div {
  width: 49.6%;
  margin-bottom:-10px;
}
.input-param-form{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  list-style: none;
  color: #333;
  font-weight: 400;
  font-size: 1rem;
  font-family: sans-serif;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  border: 1px solid #ECECED;
  margin-top: 10px;
  margin-bottom: 10px;
}
.triggered-input{
  border: 2px solid #FFD700;
  transition: all 0.2s;
}
.non-trriggered{
  border: 1px solid #ECECED;
  transition: all 0.2s;
}
.edit-input-form{
 width: Fill (322.5px)px;
height: Hug (56px)px;
padding: 8px;
gap: 10px;
border-radius: 8px;
border: 1px 0px 0px 0px;
opacity: 0px;
border: 1px solid var(--Neutral-N40, #E0E0E1)
}
.edit-input-form-text{
  min-height: 45px;
  height: 43px;
  max-height: 70px;
}
.edit-input-form:focus{
  outline: none;
}
.edit-label-form{
font-size: 13px !important;
font-weight: 600 !important;
line-height: var(--Fontline-heightM);
letter-spacing: var(--Fontletter-spacingS);
text-align: left;
color: #2B2E31 !important;
margin-bottom: 25px;
}