body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Firefox */
::-moz-scrollbar {
  width: 6px;
}
::-moz-scrollbar-track {
  background: #f0f0f0;
}
::-moz-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.main-content{
  width: 100%;

}
.sidebar-wrapper{
  position: sticky;
  height: 90vh;
  top: 10vh;
  padding-top: 40px;
}
h4{
font-weight: 900;
    font-size: 23px;
    color: var(--neutral-medium);
    padding-left: 5px;}