.sign-up-content {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .sign-up-sub-content {
        flex: 1;
        height: 100vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        position: relative;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 25px;
        }

        .logo-back {
            width: 32px;
            height: 32px;
            background-image: url('../../assets/new-design-icons/back_arrrow.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
        }

        .form-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .form-section {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                padding: 0 120px;
                gap: 50px;

                .arabic {
                    text-align: right !important;
                }

                .greeting {
                    font-weight: 550;
                    font-size: 2.6rem;
                    width: 100%;
                    text-align: left;
                }

                .signup-form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .side-by-side {
                        display: flex;
                        gap: 20px;
                        width: 100%;

                        div {
                            flex: 1;
                        }
                    }
                }

                .create-account {
                    height: 56px;
                    background-color: #2F14E5;
                    color: white;
                    border-radius: 8px;
                    font-weight: 600;
                    font-size: 1.2rem;
                    box-shadow: none;
                }

                .disabled {
                    background-color: #F5F6F6;
                    color: #B0B0B0;
                    cursor: not-allowed;
                }

                .signin-suggestion {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #484A4D;

                    .link {
                        color: #2F14E5;
                        margin-left: 10px;
                        font-style: none;
                        text-decoration: none !important;
                        font-weight: 600;
                    }
                }
            }
        }

    }
}