.chat-page-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
  .ask-page-header{
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;

    .left-section {
      margin-left: 30px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;

      .close {
        border-right: 1px solid #E5E5E5;
        padding-right: 15px;
      }

      .title {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .name {
          color: #2B2E31;
          font-weight: 600;
          display: flex;
          align-items: center;
          font-size: 18px;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .right-section {}
  }
  .sub-header{
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px;
    background-color: #FAFAFA;
    width: 100%;
    .header-file{
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      max-width: 400px;
      overflow: hidden;
      border-radius: 4px;
      padding: 5px;
      background-color: #EAE8FC;
      .icon{
        width: 30px;
        height: 30px;
      }
      .title{
        font-size: 16px;
        font-weight: 500;
        color: #2B2E31;
        text-overflow: ellipsis;
        word-break: break-all;
        max-width: 200px;
        overflow: hidden;
        max-height: 25px;
      }
    }
  }
  .ask-page-body{
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    .body-interactions-container{
      border-right: 1px solid #E5E5E5;
      flex: 3;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      gap: 15px;
    .body-chats-container{
      min-height: 65vh;
      max-height: 65vh;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      gap: 15px;
      width: 100%;
      .body-start-chat{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 120px;
        .start-chat-title{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .title{
            font-size: 24px;
            font-weight: 600;
            color: #2B2E31;
          }

        }
        .suggest{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          margin-top: 100px;
          .suggest-card{
            flex: 1;
            max-width: 20%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 12px;
            background-color: white;
            font-size: 15px;
            border: 1px solid #AA9FF4;
            color: #707274;
          }
          .suggest-card:hover{
            cursor: pointer;
            background-color: #d6d1f4;
          }
        }
      }
      .body-chat{
        border-radius: 7px;
        padding: 10px;
        border: 1px solid #E5E5E5;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: right;
        align-items: right;
        gap: 60px;
        
        .question-container{
          display: flex;
          flex-direction: row;
          justify-content: right;
          align-items: right;
          max-width: 100%;
          position: relative;
          .question{
          background-color: #EAE8FC;
          padding: 10px;
          border-radius: 10px 10px 0 10px;
          position: absolute;
          right: 0;
          }
        }
        .answer{
          padding: 10x;
        }
      }
    }
    .body-interactions-input-container{
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 5px;
      .ask-input{
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        flex: 9;
      }
      .ask-button{
        flex: 1;
        border-radius: 20px;
        background-color: #2F14E5;
        color: white;
      }
    }
  }
    .body-conversations-container{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      .conversations-container{
        min-height: 200px;
        max-height: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;
        overflow-y: auto;
        .add-conversation-container{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 70px;
          margin-bottom: 20px;
          margin-top: 10px;
        }
        .conversation{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
      .add-conversation{
        background-color: #EAE8FC;
        padding: 5px;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        border-radius: 10px;
        width: 180px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .add-conversation-form{
        margin-left: 20px;
        background-color: #EAE8FC;
        padding: 5px;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        border-radius: 10px;
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        position: relative;
        padding-top: 15px;
        margin-top: 10px;
        .close{
          position: absolute;
          right: 5px;
          top: 5px;
          width: 10px;
          height: 10px;
          cursor: pointer;
          color: red;
          border: 1px solid red;
          border-radius: 50%;
          font-size: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .add-conversation-input{
          width: 100%;
          border-radius: 20px;
          font-size: 12px;
          padding: 10px;
        }
      }
    }
  }
}