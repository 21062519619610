.language-add-form{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  .translation-entry{
    display: flex;
    width: 70%;
    justify-content: space-between;
    margin-top: 8px;
    input{
      margin-right: 5px;
      margin-top: 0% !important;
    }
    select{
      height: 38px !important;
    }
  }
}