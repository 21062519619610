.formPageInput-edition {
    .edit-contract-clause-input-header-form {
        font-size: 20px;
        color: #556581;
        z-index: 1;
        opacity: 1 !important;
        background-color: white;
        padding-bottom: 0px;
        margin-bottom: 20px !important;
        width: 100%;
    }

    .edit-contract-clause-input {
        border: none;
    }

    .selected-input-container {
        border: 2px dashed var(--primary-color);
        transition: all 0.8s ease;
        opacity: 0.9;
        padding: 10px;
    }

    .variable-input {
        cursor: pointer;
    }

    .param-count-label {
        font-weight: 700;
        font-size: 12px;
        color: var(--primary-color-darker);
    }

    .input-group-title,
    .input-group-subtitle {
        font-weight: 700;
        color: var(--primary-color-darker);
    }

    .input-group-title {
        font-size: 17px !important;
    }

    .input-group-subtitle {
        font-size: 14px !important;
    }

    .benificial-button {
        font-weight: 600;
        background-color: var(--primary-color-medium);
        color: white;
        padding: 7px;
        min-width: 38%;
        height: fit-content;
    }

    .grey-border {
        border: 2px solid #acabba !important;
        border-radius: 4px;
    }

    .green-border {
        border: 2px solid var(--ai-success-medium) !important;
        border-radius: 4px;
    }

    .radio-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .switch input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .switch label {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #ccc;
        border-radius: 50%;
        transition: background-color 0.4s;
        cursor: pointer;
    }

    .switch input[type="checkbox"]:checked+label {
        background-color: var(--primary-color);
        border: 1px solid grey;
    }

    .switch label::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-color: white;
        border-radius: 50%;
        transition: background-color 0.4s;
    }

    .switch input[type="checkbox"]:checked+label::after {
        padding: 2px;
    }

    .ai-validate-button {
        font-weight: 600;
        font-size: 12px;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        transition: transform 0.1s ease-in-out;
    }

    .ai-validate-button:active {
        transform: scale(0.95);
    }

    .ai-response {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .up-active {
        color: var(--ai-success-medium);
    }

    .down-active {
        color: var(--ai-error-medium);
    }

    .inactive {
        color: var(--primary-color);
    }

    .wrapper-form-input {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        // margin-top: -35px;
        max-height: 62vh !important;
        overflow-y: auto;
        padding-bottom: 100px;
    }

    @media screen and (min-width: 1300px) and (max-width: 1500px) {
        .wrapper-form-input {
            max-height: 500px;
        }
    }

    .wrapper-form-input::-webkit-scrollbar {
        width: 8px;
    }

    .wrapper-form-input::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 4px;
    }

    .wrapper-form-input::-webkit-scrollbar-thumb {
        background-color: var(--neutral-light);
        border-radius: 4px;
    }

    .wrapper-form-input::-webkit-scrollbar-thumb:hover {
        background-color: var(--neutral-medium);
    }

    .form-item-input-edition {
        width: 50%;
        display: flex;
        align-items: center;
        height: fit-content;
        margin-top: auto;
        margin-bottom: auto;
    }
    label {
        font-weight: 700;
        font-size: 12px;
        color: #2f14e5;
        font-style: normal;}
        .form-check-label {
            color: #1f2b3a;
            font-size: 11.9px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.64px;
        }
}