.sidebar-container {
    transition: all 0.3s;
    border-right: 1px solid #E5E9F2;
    position: fixed;

    .lets-chat{
        display: flex;
        justify-content: right;
        flex-direction: row;
        align-items: flex-end;
        height: 80px;
        width: 250px;
        
        .container{
          cursor: pointer;
          border: 2px solid var(--secondary-color-medium);
          border-radius: 20px;
          height: 90px;
          width: 210px;
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          .icon{
            width: 40px;
            height: 40px;
          }
          .title{
            font-weight: bolder;
            color: var(--secondary-color) !important;
            position: relative;
            display: inline-block;
          }
          .triangle{
            position: absolute;
            bottom: -10px;
            right: 20px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid var(--secondary-color-medium);
          }
        }
    }
    .sidebar {
        border-right: 3px solid #e6e6e6;
        width: 15%;
        max-width: 240px;
        height: 90vh;
        overflow: hidden;
    }

    .sidebar-item {
        font-weight: 700;
        font-size: 14px;
        color: var(--neutral-dark);
        display: flex;
        padding: 9px 10px 10px 10px;
        white-space: nowrap;
        text-decoration: none;
    }

    .sidebar-list-name {
        font-weight: 700;
        font-size: 14px;
        color: var(--neutral-dark);
        display: flex;
        white-space: nowrap;
        text-decoration: none;
    }

    .sidebar-list-element {}

    .sidebar-list-items {
        padding-left: 15px;
    }

    .sidebar-item:hover {
        transition: all 0.1s;
        color: #2F14E5;
    }

    .sidebar-list-header {
        font-weight: 700;
        font-size: 14px;
        color: var(--neutral-dark);
        display: flex;
        padding: 9px 10px 10px 10px;
        white-space: nowrap;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        gap: 7px;
        cursor: pointer;
    }

    .sidebar-list-header:hover .sidebar-list-name {
        transition: all 0.1s;
        color: #2F14E5;
    }

    .half-border-bottom {
        width: 50%;
        display: flex;
        padding-bottom: 5px;
        gap: 5px;
    }

    .located-border {
        border-bottom: 2px solid #2F14E5;
    }

    .located {
        color: #2F14E5;
    }

    .located::after {}

    .sidebar-list {
        margin-top: 35px;
        // height: 65vh;
        align-items: left;
        text-align: left;
        justify-content: left;
        border-bottom: 1px solid #e6e6e6;
    }

    p {
        font-weight: 700;
        font-size: 13px;
        color: var(--neutral-dark);
        max-width: 832px;
    }

    button {
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: white;
        border-radius: 4px !important;
        color: var(--neutral-medium);
        font-weight: 700;
        border: 1px solid var(--neutral-light);
    }

    .sidebar-logo {
        width: 52px;
        height: 44px;
        margin: auto;
        transition: all 0.1s;
    }

    .sidebar-logo-hover {
        margin: auto;
        width: 168px;
        height: 56px;
        transition: all 0.3s;
    }

    .sidebar-logo-container {
        min-height: 60px;
    }

    .sidebar-item-icon {
        width: 20px;
        height: 20px;
    }

    .sidebar-item-text {
        color: #526484;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.15px;
        white-space: nowrap;
        transition: all 0.3s;
    }

    .sidebar-user-icon {
        border-radius: 66px;
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        margin: auto;
    }

    .sidebar-user-role {
        color: #798BFF;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 11px;
    }

    .sidebar-user-name {
        color: #526484;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }

    .sidebar-user-text {
        white-space: nowrap;
    }

    .topic-expand {
        transition: all 0.3s ease-in-out;
    }

    .rotate {
        transform: rotate(-90deg);
        transition: all 0.3s ease-in-out;
    }

    .list-located {
        color: #2F14E5 !important;
    }
    .sidebar-wrapper {
        padding-right: 8px; 
        display: flex;
        flex-direction: column;
        flex: 1
          }
      .support{
          margin-top: auto;
      }
      .sidebar-basic-items {
        max-height: calc(90vh - 150px);
        overflow: hidden;
    
        &:hover {
          overflow-y: auto; 
        }
        &::-webkit-scrollbar {
          width: 6px; 
        }
    
        &::-webkit-scrollbar-thumb {
          background-color: #c4c0c0b5; 
          border-radius: 3px;
        }
    
        &::-webkit-scrollbar-thumb:hover {
          background-color: #d5d2d2bb;
        }
    
        &::-webkit-scrollbar-track {
          background-color: #f1f1f1; 
        }
      }
}
  