.reglement-page {
  .status-active{
    background-color: #e5f9d5;
    border: 1px solid #d6f6bc;
    color: #484A4D;
    margin: 5px 30px;
    padding: 7px 17px;
    border-radius: 10px;
    font-weight: 600;
  }
  .reglement-page-header {
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;

    .left-section {
      margin-left: 30px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;

      .close {
        border-right: 1px solid #E5E5E5;
        padding-right: 15px;
      }

      .title {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .name {
          color: #2B2E31;
          font-weight: 600;
          display: flex;
          align-items: center;
          font-size: 18px;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .right-section {}
  }

  .reglement-page-body {
    .reglement-files-list {
      padding: 30px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex-wrap: wrap;
      min-height: 65vh;
      .ressource-card {
        width: 220px;
        height: 170px;
        background-color: #EAE8FC;
        border: 1px solid #2F14E5;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;
        overflow: hidden;
        .title {
          color: #2F14E5;
          font-weight: 600;
          text-align: center;
          text-overflow: ellipsis;
          max-width: 200px;
          word-break: break-all;
        }
      }

      .add-card {
        cursor: pointer;
        width: 220px;
        height: 170px;
        background-color: #FAFAFA;
        border: 1px solid #ECECED;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .title {
          color: #707274;
          font-weight: 600;
          text-align: center;
          text-overflow: ellipsis;
        }
      }
    }
    .actions{
      width: 100%;
      display: flex;
      flex-direction: row;
      .disabled{
        cursor: not-allowed !important;
        background-color: #E5E5E5 !important;
        color: white !important;
      }
       .commit-button{
        cursor: pointer;
        padding: 12px, 16px, 12px, 16px !important;
        background-color: #23C286;
        color: white;
        font-weight: 600;
        width: 130px;
        height: 60px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    }
   
  }
}