.create-account-popup {
.modal-content {
  min-width: 500px;
  min-height: 300px;
}

.content {
  font-size: 28px;
  margin: auto;
  color: rgb(20, 20, 20);
}

.modal-header {
  text-align: center !important;
}

.create-account-header {
  color: #300962;
  text-align: center;
}

.buttons,
.actions-create-account {
  display: flex;
  justify-content: space-around;
  margin-bottom: 35px;
  margin-top: 30px;

}

.actions-create-account {
  margin-top: 50px;
}

.buttons button,
.actions-create-account button {
  padding: 10px;
  width: 150px;
  font-weight: bold;
  box-shadow: none;
}

.no-account,
.previous-button {
  background-color: #f0f0f0;
  color: #b3b3b3;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 20px;
  margin-bottom: 20px;
}

.checkbox-container input {
  margin-right: 10px;
}

.checkbox-container label {
  color: rgba(33, 33, 33, 0.693) !important;
  font-size: 11px;
}

.error-message {
  color: red;
  margin-top: 1%;
  margin-bottom: -5%;
  font-size: 13px;
}


  .form-group {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    width: 60%;

    .form-control {
      width: 100% !important;
    }

    label {
      color: #212529;
      font-weight: 500;
      font-size: 17px;
    }
  }


.content-form {
  font-size: 16px;
  text-align: center;
  color: rgb(32, 32, 32);
  margin-top: 10px;
}

.form-create-account {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
}