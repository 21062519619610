  .popup-logout {
      position: absolute;
      top: 105%;
      right: 50px;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 300px;
      border-radius: 8px;


      .logout-btn {
          color: #526484;
          border: none;
          padding: 8px 16px;
          cursor: pointer;
          text-align: center;
          letter-spacing: 1px;
      }
  }
  .popup-logout-arabic{
    right: -250px !important;
  }