.template-render {
  .param {
    color: var(--primary-color-medium);
    font-weight: initial !important;
  }

  .param-value {
    color: var(--primary-color-darker);
    font-weight: initial !important;
  }

  .comment {
    color: var(--neutral-medium);
    font-weight: 600;
  }

  .template-selected-text {
    font-size: 16px;
  }

  .highlighted-text {
    background-color: #fff4e7;
  }

  .template-selected-text {
    background-color: white;
    border: none !important;
    outline: none;
    overflow-wrap: normal !important;
    cursor: text;
  }

  .comment-edit {
    cursor: text !important;
  }

  .comment-prefix {
    color: var(--primary-color-medium) !important;
    font-weight: 900 !important;
  }

  .segment-text-line {
    background-color: white;
    border: none !important;
    outline: none;
    overflow-wrap: normal !important;
    cursor: text;
  }

  .option-label {
    border: none !important;
    outline: none;
    overflow-wrap: normal !important;
    cursor: text;
    font-size: 10px;
    position: absolute;
    bottom: -10px;
    color: #637491;
  }

  .insert-text {
    display: inline-block;
    transform: scaleX(-1);
    color: #637491;
    font-size: 10px;
  }
}

.template-render {
  span {
    word-break: break-word;
  }
}

.segmented-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  tbody {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  td {
    border: 1px solid #000000;
    padding: 8px;
    text-align: left;
    flex: 1;
  }

  tr {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: row;
  }
}