.form-template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  .set-groups-container{
    position: absolute;
    z-index: 999;
  }

  .form-template-title {
    display: flex;
    align-items: center;
    justify-content: center;

    .form-template-title-details {
      display: flex;
      align-items: left;
      flex-direction: column;

      .title {
        color: #2B2E31;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        align-items: center;
        .input-title{
          font-size: 16px;
          font-weight: 600;
          color: #2B2E31;
          border-radius: 8px;
          border: 1px solid #D9D9D9;
          width: 350px;
        }
      }

      .modified {
        color: #8D8E90;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .form-template-pagination {
    color: #2B2E31;
    font-weight: 600;
    font-size: 16px;
  }
}

.form-template-sidebar-section{
  padding: 30px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}