.progress-container {
  .bg-custom {
    background-color: var(--secondary-color-medium);
  }

  .progress-expanded {
    height: 15px !important;
    border-radius: 100px !important;
  }

  .progress-bar-expanded {
    border-radius: 100px !important;
  }

  .progress-collapsed {
    height: 5px !important;
    border-radius: 100px !important;
    min-width: 84px !important;
    margin-top: 5px !important;
  }

  .progress-bar-collapsed {
    border-radius: 100px !important;
  }

  .progress-collapsed-container {
    max-width: 135px !important;
    gap: 7px !important;
  }

  .progress-expanded-container h6 {
    font-weight: 500 !important;
    font-size: 14px !important;
    color: var(--neutral-medium) !important;
    letter-spacing: -1.2px;
  }
}

.progress-collapsed-container{
  position: relative;
  background-color: #f5f6fa;
  border: 1px solid #e0e0e0;
  border-radius: 100px;
  height: 20px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  div{
    background-color: #9585ff !important;
    color: var(--secondary-color-medium) !important;
    padding: 5px;
    border-radius: 100px;
  }
  h6 {
    position: absolute;
    right: 0;
  }
}