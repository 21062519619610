.beneficials-list {
  padding: 20px;
  .table-container {
    max-height: 640px;
    width: 100%;
    overflow-y: auto;
    border: 1px solid #ccc;
    position: relative;
    margin-top: 3%;
    font-size: 15px;
  }
  .fixed-header-table {
    width: 100%;
    border-collapse: collapse;
  }
  .fixed-header-table th,
  .fixed-header-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  .arabic-table th,
  .arabic-table td {
    text-align: right;
  }

  .fixed-header-table thead th {
    position: sticky;
    top: 0;
    background-color: #f1f1f1;
    z-index: 1;
  }
.button-add-new-beneficial{
  margin-top: 3%;
}
  .action-add-beneficial {
    background-color: #2F14E5 ;
    box-shadow: none ;
    color: white;
    border-radius: 27px ;
    width: 220px ;
    margin: 0;
    font-size: 15px;
    height: 50px;
  }
  .delete-beneficial{
    margin-left: 20px;
  }
  .edit-beneficial{
    cursor: pointer;
  }
}