.actions-import-rules{
    position: absolute;
    bottom:6%;
}
.table-cron{
    margin-bottom: 50px;
}
.table-cron th , .table-penality th{
    font-size: 75%;
}
.table-cron td , .table-penality td{
  font-size: 12.5px;

}
.table-import-rules{
    max-height: 50vh !important;
  }
  .action-import-rules{
    width: 100% ;
    padding: 15px;
    margin-top: 25px;
  }