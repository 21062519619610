.date-input-container {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    .input-with-icon {
        position: relative;
        width: 100%;
    }

    .input-icon {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        color: var(--primary-color);
        pointer-events: none;
    }

    .date-input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ced4da;
        border-radius: 4px;
        font-size: 1rem;
        line-height: 1.5;
        color: var(--primary-color);
        background-color: #fff;
        background-clip: padding-box;
        appearance: none;
        cursor: pointer;
    }

    .date-input:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-darker);
    }

    .grey-border {
        border: 2px solid #acabba !important;
    }

    .green-border {
        border: 2px solid var(--ai-success-medium) !important;
    }

    .react-datepicker__input-container input {
        border: none !important;
        cursor: pointer;
        outline: none !important;
    }

    .react-datepicker {
        right: 15%;

        @media screen and (min-width: 1300px) and (max-width: 1500px) {
            right: 22%;
        }
    }

    .react-datepicker__triangle::after {
        left: -18px !important;
    }

    @media screen and (min-width: 1300px) and (max-width: 1500px) {
        .react-datepicker__triangle::after {
            left: -4px !important;
        }
    }

    .react-datepicker__triangle::before {
        left: -18px !important;
    }

    @media screen and (min-width: 1300px) and (max-width: 1500px) {
        .react-datepicker__triangle::before {
            left: -4px !important;
        }
    }
}