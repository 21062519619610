.main-function {
    display: inline-block;
    padding-left: 16px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    padding-bottom: 15% !important;
}
.admin-main-functions{
    width: 100% !important;
    height: 100% !important;
    min-height:220px !important ;
    position: relative;
    padding-bottom: 15%;
}

.main-function::before {
    content: "";
    display: block;
    position: absolute;
    top: -30px;
    left: -1px;
    width: 200px;
    height: 30.8px;
    background-color: white;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-radius: 4px 4px 0 0;
}

.main-function h2 {
    font-weight: bolder;
    color: var(--secondary-color) !important;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 20px;
}
.main-function h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px; /* Adjust the width (length) of the border-bottom */
    height: 7px;
    background-color: var(--secondary-color-medium);
}
.main-function .btn {
    width: 100%;
    height: 50px;
    background-color: var(--primary-color-medium);
    color: white;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02), 0px 13px 8px rgba(51, 18, 239, 0.07),
        0px 6px 6px rgba(51, 18, 239, 0.12), 0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
    border-radius: 4px;
    border: none;
    position: absolute;
    bottom: 2%;
    width: 90% !important;
}
.btn-action-admin{
    left: 5%
}