.file-input-component {
    .file-input-container {
        position: relative;
        display: inline-block;
    }

    .file-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .file-label {
        padding: 5px 10px;
        background-color: #2196f3;
        color: #fff;
        cursor: pointer;
        border-radius: 4px;
    }

}