.edit-contract-clause-input {
    border-radius: 12px;
    box-shadow: 1px 1px 1px 1px #e5e9f2, -1px -1px 1px 1px #e5e9f2;

    .edit-contract-clause-input-header {
        font-size: 16px;
        color: #556581;
        display: flex;
        flex-direction: row;
        width: 94%;
    }
    .template-param-element{
        width: -webkit-fill-available !important;
        margin-bottom: 20px;
        background-color: white;
    }
    .selected-input-container {
        border: 2px dashed var(--primary-color);
        transition: all 0.8s ease;
        opacity: 0.9;
        padding: 10px;
        overflow-y: auto;
        max-height: 79vh !important;
        overflow-x: hidden;
    }

    .variable-input {
        cursor: pointer;
    }

    .param-count-label {
        font-weight: 700;
        font-size: 12px;
        color: var(--primary-color-darker);
    }

    .input-group-title,
    .input-group-subtitle {
        font-weight: 700;
        color: var(--primary-color-darker);
    }

    .input-group-title {
        font-size: 17px !important;
    }

    .input-group-subtitle {
        font-size: 14px !important;
    }

    .grey-border {
        border: 2px solid #acabba !important;
        border-radius: 4px;
    }

    .green-border {
        border: 2px solid var(--ai-success-medium) !important;
        border-radius: 4px;
    }

    .radio-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .switch input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .switch label {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #ccc;
        border-radius: 50%;
        transition: background-color 0.4s;
        cursor: pointer;
    }

    .switch input[type="checkbox"]:checked+label {
        background-color: var(--primary-color);
        border: 1px solid grey;
    }

    .switch label::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-color: white;
        border-radius: 50%;
        transition: background-color 0.4s;
    }

    .switch input[type="checkbox"]:checked+label::after {
        padding: 2px;
    }

    .ai-validate-button {
        font-weight: 600;
        font-size: 12px;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        transition: transform 0.1s ease-in-out;
    }

    .ai-validate-button:active {
        transform: scale(0.95);
    }

    .ai-response {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .up-active {
        color: var(--ai-success-medium);
    }

    .down-active {
        color: var(--ai-error-medium);
    }

    .inactive {
        color: var(--primary-color);
    }
    .subclause-header{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .subclause-header-edit{
            height: 30px;
            cursor: pointer;
        }
    }
}