.pagination-container {
  display: flex;
  justify-content: center; 
  margin-top: 16px;

  .pagination-content {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    width: 100%;
    max-width: 90%; 
  }

  .pagination {
    display: flex;
    align-items: center;
    gap: 8px; 
  }

  .page-size-selector {
    display: flex;
    align-items: center;
    gap: 8px; 

    label {
      font-size: 14px;
      color: #000000;
    }

    select {
      padding: 4px 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
    }
  }

  button {
    padding: 8px 14px;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid rgb(222, 218, 218);
    box-shadow: none !important;
    &.active {
      background-color: #4834d4;
      color: #fff;
    }

    &:hover {
      background-color: #798bff;
      color: #fff;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: #f0f0f0;
      color: #a0a0a0;
    }
  }
}
