.full-width{
  max-width: none !important;
}
.add-subclause-container {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  overflow: hidden;
  transition: width 0.5s ease;
  max-width: 200px;
  cursor: pointer;
  &:hover {
    .add-subclause-icon {
      transform: rotate(270deg);
    }
    .add-subclause-text {
      transform: translateX(160px);
    }
  }


  .add-subclause-icon {
    transition: transform 0.5s ease;
    z-index: 1;
    overflow: hidden;
    background-color: white;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-subclause-text {
    position: absolute;
    left: -130px;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
  }
  .add-clause-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    .add-clause-name{
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
      align-items: center;
      .add-clause-name-label{
        min-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .add-clause-name-input{
        height: 25px;
        width: 30vw;
      }
    }
    .add-clause-actions{
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .add-clause-button{
        width: 70px;
      }
    }
  }
}
