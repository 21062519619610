.dialog-input-container {
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.dialog-search {
  height: 20px !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.dialog-input-label {
  color: #212529;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 10px;
}
.dialog-param-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 220px;
  overflow-y: auto;
}
.dialog-element {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
}
.dialog-element:hover {
  border: 1px solid #2f14e5;
}

.dialog-add-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  margin: 4px;
  border-radius: 5px;
  border: 1px solid #2f14e5;
  background: #2f14e5;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.26px;
  height: 20px;
  cursor: pointer;
}

.dialog-input {
  height: 30px !important;
  padding: 0px 5px !important;
}

.dialog-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.dialog-button {
  border-radius: 5px;
  border: 1px solid #2f14e5;
  background: #2f14e5;
  color: #fff;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.26px;
  height: 20px;
}

.dialog-container {
  padding: 10px;
  border: 1px solid #2f14e5;
  border-radius: 20px;
}

.error-message-dialog {
  color: red;
  font-size: 12px;
}

.warning-message-dialog {
  color: yellowgreen;
  font-size: 12px;
}

.dialog-button-disabled {
  background: #eaefedb0 !important;
  border: 1px solid #eaefedb0;
  color: gray;
  box-shadow: none !important;
}
.close-dialog {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  border-radius: 50%;
  color: #726b6b;
  border: 1px solid #2f14e5 !important;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-dialog:hover {
  color: #2f14e5;
  background-color: #eee3fd;
}
