.user-add-popup {
  .modal-content {
    min-width: 400px;
  }
  .body-add-user {
    min-width: 400px;
  }
  .error-message {
    color: red;
    margin-top: 1%;
    margin-bottom: -5%;
    font-size: 13px;
  }
  .form-group {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    label{
      color: #344357;
      font-size: 17px;
      font-style: normal;
      font-weight:normal;
      line-height: 23.1px;
    }
    .form-control {
      width: 100% !important;
    }
  }
  .actions-popup-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .user-role-select {
    width: 100%;
    margin-top: 6%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }

  .user-role-select:focus {
    outline: 0;
    box-shadow: none;
  }

  .user-role-select option {
    padding: 5rem !important;
    font-size: 1rem;
    color: black;
    cursor: pointer !important;
  }


  .actions-popup-user {

    .add-signle-user,
    .cancel-adding-user {
      padding: 8px;
      width: 110px;
      box-shadow: none;
    }

    .cancel-adding-user {
      color: #b3b3b3;
      background-color: #f0f0f0;
    }
  }

}
